import Footer from "components/Footer"
import Header from "components/Header"
import { Helmet } from "react-helmet"
import { useBackButton } from "library/Loader/TransitionUtils"
import Scroll from "library/Scroll"
import { useTrackPageReady } from "library/pageReady"
import useCSSHeightVariables from "library/useCssHeightVariables"
import { useEffect } from "react"
import styled, { createGlobalStyle, css } from "styled-components"
import colors from "styles/colors"
import ContactModal from "./Contact/Modal"
import { withPrefix } from "gatsby"

import Preloader from "./Preloader"
import Transition from "./Transition"

interface LayoutProps {
	children: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
	useTrackPageReady()
	useBackButton()
	useCSSHeightVariables()

	useEffect(() => {
		const reload = () => {
			window.location.reload()
		}

		window.addEventListener("orientationchange", reload)

		return () => {
			window.removeEventListener("orientationchange", reload)
		}
	}, [])

	return (
		<>
			<GlobalStyle />
      <Helmet>
        <script src={withPrefix('rb2b.js')} type="text/javascript" />
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/39878266.js"></script>
        <script id="vtag-ai-js" async src="https://r2.leadsy.ai/tag.js" data-pid="1wiDcueE7qePijE6t" data-version="062024"></script>
      </Helmet>
			<Header />
			<Transition />
			<Preloader />
			<ContactModal type="contact" />
			<ContactModal type="demo" />
			<Scroll className="scroller">
				<Main id="main">
					{children}
					<Footer />
					<Blur id="main-blur" />
				</Main>
			</Scroll>
		</>
	)
}

const Main = styled.main`
  overflow-x: clip;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  position: relative;
`

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 9;
`

// TODO: configure a default text color

const globalCss = css`
  /* default text styles */
  html {
    font-family: sans-serif;
    color: ${colors.charcoal100};
    background: ${colors.white};
    -webkit-font-smoothing: antialiased;
  }

  * {
    /* need this so that fonts match figmas */
    text-rendering: geometricprecision;
  }

  /* fixes for visbug */
  vis-bug {
    position: fixed;
    z-index: var(--layer-1);
  }

  visbug-metatip,
  visbug-handles,
  visbug-ally {
    position: absolute;
    z-index: var(--layer-top);
  }

  /** restore default focus states for elements that need them */
  *:focus-visible {
    outline: 2px solid #00f8;
  }
`

const GlobalStyle = createGlobalStyle`${globalCss}`
