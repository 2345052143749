import gsap from "gsap";
import useAnimation from "library/useAnimation";
import { useRef, useState } from "react";
import styled from "styled-components";
import colors from "styles/colors";

const INITIAL_DURATION = 1;
const PAUSE_TIME = 0.8;
const EASE = "power3.inOut";
const ARROW_COLOR = colors.purpleDark01;

export default function ClusterArrowsDown({ canPlay }: { canPlay: boolean }) {
  const topWrapper = useRef<SVGSVGElement | null>(null);
  const bottomWrapper = useRef<SVGSVGElement | null>(null);
  const [tl1, setTl1] = useState<gsap.core.Timeline | null>(null);

  useAnimation(() => {
    if (!bottomWrapper.current || !topWrapper.current) return;

    gsap.set(topWrapper.current, { y: "-100%", autoAlpha: 0 });

    const tl = gsap.timeline({
      repeat: -1,
      paused: true,
      repeatDelay: PAUSE_TIME,
    });

    tl.to(
      bottomWrapper.current,
      {
        y: "100%",
        autoAlpha: 0,
        duration: INITIAL_DURATION,
        ease: EASE,
        onComplete: () => {
          gsap.set(bottomWrapper.current, { y: "-100%", autoAlpha: 0 });
        },
      },
      0,
    )
      .to(
        bottomWrapper.current,
        {
          y: "+=100%",
          autoAlpha: 1,
          duration: INITIAL_DURATION,
          ease: EASE,
        },
        INITIAL_DURATION + PAUSE_TIME,
      )
      .to(
        topWrapper.current,
        {
          y: 0,
          autoAlpha: 1,
          duration: INITIAL_DURATION,
          ease: EASE,
        },
        0,
      )
      .to(
        topWrapper.current,
        {
          y: "+=100%",
          autoAlpha: 0,
          duration: INITIAL_DURATION,
          ease: EASE,
        },
        INITIAL_DURATION + PAUSE_TIME,
      );
    setTl1(tl);
  }, []);

  useAnimation(() => {
    if (canPlay) {
      tl1?.play();
    } else {
      tl1?.seek(0).pause();
    }
  }, [canPlay, tl1]);

  return (
    <Container>
      <Wrapper
        ref={topWrapper}
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.8031 80.0827C25.8559 80.0298 25.9277 80.0001 26.0025 80.0001L26.7365 80.0001C26.8113 80.0001 26.8831 80.0298 26.936 80.0827L38.0508 91.1976L36.4951 92.7532L27.4739 83.7319L27.4739 102L25.2739 102L25.2739 83.7232L16.2579 92.7392L14.7022 91.1835L25.8031 80.0827Z"
          fill={ARROW_COLOR}
        />
        <path
          d="M59.8031 80.0827C59.8559 80.0298 59.9277 80.0001 60.0025 80.0001L60.7365 80.0001C60.8113 80.0001 60.8831 80.0298 60.936 80.0827L72.0508 91.1976L70.4951 92.7532L61.4739 83.7319L61.4739 102L59.2739 102L59.2739 83.7232L50.2579 92.7392L48.7022 91.1835L59.8031 80.0827Z"
          fill={ARROW_COLOR}
        />
        <path
          d="M59.8031 48.0827C59.8559 48.0298 59.9277 48.0001 60.0025 48.0001L60.7365 48.0001C60.8113 48.0001 60.8831 48.0298 60.936 48.0827L72.0508 59.1976L70.4951 60.7532L61.4739 51.7319L61.4739 70.0001L59.2739 70.0001L59.2739 51.7232L50.2579 60.7392L48.7022 59.1835L59.8031 48.0827Z"
          fill={ARROW_COLOR}
        />
        <path
          d="M93.8031 15.0827C93.8559 15.0298 93.9277 15.0001 94.0025 15.0001L94.7365 15.0001C94.8113 15.0001 94.8831 15.0298 94.936 15.0827L106.051 26.1976L104.495 27.7532L95.4739 18.7319L95.4739 37.0001L93.2739 37.0001L93.2739 18.7232L84.2579 27.7392L82.7022 26.1835L93.8031 15.0827Z"
          fill={ARROW_COLOR}
        />
        <path
          d="M93.8031 80.0827C93.8559 80.0298 93.9277 80.0001 94.0025 80.0001L94.7365 80.0001C94.8113 80.0001 94.8831 80.0298 94.936 80.0827L106.051 91.1976L104.495 92.7532L95.4739 83.7319L95.4739 102L93.2739 102L93.2739 83.7232L84.2579 92.7392L82.7022 91.1835L93.8031 80.0827Z"
          fill={ARROW_COLOR}
        />
        <path
          d="M93.8031 48.0827C93.8559 48.0298 93.9277 48.0001 94.0025 48.0001L94.7365 48.0001C94.8113 48.0001 94.8831 48.0298 94.936 48.0827L106.051 59.1976L104.495 60.7532L95.4739 51.7319L95.4739 70.0001L93.2739 70.0001L93.2739 51.7232L84.2579 60.7392L82.7022 59.1835L93.8031 48.0827Z"
          fill={ARROW_COLOR}
        />
      </Wrapper>
      <Wrapper
        ref={bottomWrapper}
        viewBox="0 0 120 120"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.8031 80.0827C25.8559 80.0298 25.9277 80.0001 26.0025 80.0001L26.7365 80.0001C26.8113 80.0001 26.8831 80.0298 26.936 80.0827L38.0508 91.1976L36.4951 92.7532L27.4739 83.7319L27.4739 102L25.2739 102L25.2739 83.7232L16.2579 92.7392L14.7022 91.1835L25.8031 80.0827Z"
          fill={ARROW_COLOR}
        />
        <path
          d="M59.8031 80.0827C59.8559 80.0298 59.9277 80.0001 60.0025 80.0001L60.7365 80.0001C60.8113 80.0001 60.8831 80.0298 60.936 80.0827L72.0508 91.1976L70.4951 92.7532L61.4739 83.7319L61.4739 102L59.2739 102L59.2739 83.7232L50.2579 92.7392L48.7022 91.1835L59.8031 80.0827Z"
          fill={ARROW_COLOR}
        />
        <path
          d="M59.8031 48.0827C59.8559 48.0298 59.9277 48.0001 60.0025 48.0001L60.7365 48.0001C60.8113 48.0001 60.8831 48.0298 60.936 48.0827L72.0508 59.1976L70.4951 60.7532L61.4739 51.7319L61.4739 70.0001L59.2739 70.0001L59.2739 51.7232L50.2579 60.7392L48.7022 59.1835L59.8031 48.0827Z"
          fill={ARROW_COLOR}
        />
        <path
          d="M93.8031 15.0827C93.8559 15.0298 93.9277 15.0001 94.0025 15.0001L94.7365 15.0001C94.8113 15.0001 94.8831 15.0298 94.936 15.0827L106.051 26.1976L104.495 27.7532L95.4739 18.7319L95.4739 37.0001L93.2739 37.0001L93.2739 18.7232L84.2579 27.7392L82.7022 26.1835L93.8031 15.0827Z"
          fill={ARROW_COLOR}
        />
        <path
          d="M93.8031 80.0827C93.8559 80.0298 93.9277 80.0001 94.0025 80.0001L94.7365 80.0001C94.8113 80.0001 94.8831 80.0298 94.936 80.0827L106.051 91.1976L104.495 92.7532L95.4739 83.7319L95.4739 102L93.2739 102L93.2739 83.7232L84.2579 92.7392L82.7022 91.1835L93.8031 80.0827Z"
          fill={ARROW_COLOR}
        />
        <path
          d="M93.8031 48.0827C93.8559 48.0298 93.9277 48.0001 94.0025 48.0001L94.7365 48.0001C94.8113 48.0001 94.8831 48.0298 94.936 48.0827L106.051 59.1976L104.495 60.7532L95.4739 51.7319L95.4739 70.0001L93.2739 70.0001L93.2739 51.7232L84.2579 60.7392L82.7022 59.1835L93.8031 48.0827Z"
          fill={ARROW_COLOR}
        />
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const Wrapper = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
