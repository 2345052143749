import LinkButton from "components/Buttons/LinkButton";
import type { SectionLinks } from "components/Header/Desktop";
import { fresponsive } from "library/fullyResponsive";
import styled, { css } from "styled-components";
import colors from "styles/colors";
import textStyles from "styles/text";

export default function ColumnContent({
  columns,
}: {
  columns?: SectionLinks[];
}) {
  return columns?.map((columnData) => {
    const { links, section } = columnData;

    return (
      <Column key={section}>
        <Kicker>{section}</Kicker>
        {links?.map((link) => {
          return (
            <LinkButton key={link.name} to={link.href} variant="arrow" dark>
              {link.name}
            </LinkButton>
          );
        })}
      </Column>
    );
  });
}

const Column = styled.div`
  display: flex;
  flex-direction: column;

  ${fresponsive(css`
    width: 98px;
    gap: 10px;
  `)}
`;

const Kicker = styled.div`
  color: ${colors.greenGray02};
  ${textStyles.bodyXXS};
`;
