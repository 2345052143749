import AnimatedIcons from "components/AnimatedIcons"
import gsap from "gsap"
import { ReactComponent as AccountingFirms } from "images/header/accountingFirms.svg"
import { ReactComponent as MidMarket } from "images/header/midMarket.svg"
import { ReactComponent as Startups } from "images/header/startups.svg"
import { DesktopOnly, TabletMobileOnly } from "library/breakpointUtils"
import useAnimation from "library/useAnimation"
import useMedia from "library/useMedia"
import { getResponsivePixels } from "library/viewportUtils"
import { type ReactNode, useState } from "react"
import colors from "styles/colors"
import links from "utils/links"

import DektopHeader from "./Desktop"
import ResponsiveHeader from "./Responsive"

export interface Link {
	name: string
	href: string
	description?: string
	icon?: ReactNode
	background?: string
	color?: string
}

export interface HamburgerSectionLinks {
	section: string
	kicker?: string
	links: Link[]
}

const SITE_LINKS_HAMBURGER: HamburgerSectionLinks[] = [
	{
		section: "Products",
		kicker: "Products",
		links: [
			{
				name: "Core Accounting",
				href: links.coreAccounting,
				description: "The modern general ledger that scales with you",
				icon: (
					<AnimatedIcons
						color={colors.orangePastel01}
						animation="cornerArrows"
					/>
				),
				background: colors.orangePastel02,
				color: colors.orangeGray02,
			},
			{
				name: "Revenue Automation",

				href: links.revenueAutomation,
				description: "Automate your end-to-end revenue process",
				icon: (
					<AnimatedIcons
						color={colors.purplePastel01}
						animation="swirlArrows"
					/>
				),
				background: colors.purplePastel02,
				color: colors.purpleGray02,
			},
		],
	},
	{
		section: "Solutions",
		kicker: "Solutions",
		links: [
			{
				name: "Startups",
				href: links.startups,
				icon: <Startups />,
				background: colors.greenPastel02,
				color: colors.orangeGray02,
			},
			{
				name: "Mid-Market",
				href: links.midMarket,
				icon: <MidMarket />,
				background: colors.orangePastel02,
				color: colors.orangeGray02,
			},
			{
				name: "Accounting Firms",
				href: links.accountingFirms,
				icon: <AccountingFirms />,
				background: colors.purplePastel02,
				color: colors.orangeGray02,
			},
		],
	},
	{
		section: "Customers",
		links: [
			{
				name: "Customers",
				href: links.customers,
			},
		],
	},
	{
		section: "About",
		kicker: "About",
		links: [
			{
				name: "Careers",
				href: links.careers,
			},
			{
				name: "Contact",
				href: links.contact,
			},
		],
	},
	{
		section: "Resources",
		kicker: "Resources",
		links: [
			{
				name: "Blog",
				href: links.blog,
			},
			{
				name: "Developers",
				href: links.developers,
			},
			{
				name: "Community",
				href: links.community,
			},
		],
	},
]

export default function Header() {
	const [open, setOpen] = useState(false)
	const extraHamburger = useMedia(true, true, false, false)

	useAnimation(() => {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: ".hero-section",
				start: "top top",
				end: `top top-=${getResponsivePixels(200)}`,
				scrub: true,
			},
			onStart: () => {
				if (!extraHamburger) return
				setOpen(false)
			},
		})

		tl.to(
			".header-link",
			{ stagger: 0.03, yPercent: -400, opacity: 0, duration: 0.5 },
			0,
		)
		tl.fromTo(
			".header-right",
			{ x: 0 },
			{ x: `-=${getResponsivePixels(85)}`, duration: 0.3 },
			0.2,
		)
		tl.fromTo(
			".desktop-hamburger",
			{ autoAlpha: 0 },
			{ autoAlpha: 1, pointerEvents: "all", duration: 0.3 },
			0.2,
		)
	}, [extraHamburger])

	return (
		<>
			<DesktopOnly>
				<DektopHeader
					open={open}
					setOpen={setOpen}
					hamburgerData={SITE_LINKS_HAMBURGER}
				/>
			</DesktopOnly>
			<TabletMobileOnly>
				<ResponsiveHeader
					open={open}
					setOpen={setOpen}
					hamburgerData={SITE_LINKS_HAMBURGER}
				/>
			</TabletMobileOnly>
		</>
	)
}
