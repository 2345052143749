import gsap from "gsap"
import useAnimation from "library/useAnimation"
import { useRef, useState } from "react"
import styled from "styled-components"
import colors from "styles/colors"

const INITIAL_DURATION = 1
const PAUSE_TIME = 0.8
const EASE = "power3.inOut"
const ARROW_COLOR = colors.greenDark01

export default function ClusterArrows({ canPlay }: { canPlay: boolean }) {
	const topWrapper = useRef<SVGSVGElement | null>(null)
	const bottomWrapper = useRef<SVGSVGElement | null>(null)
	const [tl1, setTl1] = useState<gsap.core.Timeline | null>(null)

	useAnimation(() => {
		if (!bottomWrapper.current || !topWrapper.current) return

		gsap.set(bottomWrapper.current, { y: "100%", autoAlpha: 0 })

		const tl = gsap.timeline({
			repeat: -1,
			paused: true,
			repeatDelay: PAUSE_TIME,
		})

		tl.to(
			topWrapper.current,
			{
				y: "-=100%",
				autoAlpha: 0,
				duration: INITIAL_DURATION,
				ease: EASE,
				onComplete: () => {
					gsap.set(topWrapper.current, { y: "100%", autoAlpha: 0 })
				},
			},
			0,
		)
			.to(
				topWrapper.current,
				{
					y: "0",
					autoAlpha: 1,
					duration: INITIAL_DURATION,
					ease: EASE,
				},
				INITIAL_DURATION + PAUSE_TIME,
			)
			.to(
				bottomWrapper.current,
				{
					y: "-=100%",
					autoAlpha: 1,
					duration: INITIAL_DURATION,
					ease: EASE,
				},
				0,
			)
			.to(
				bottomWrapper.current,
				{
					y: "-=100%",
					autoAlpha: 0,
					duration: INITIAL_DURATION,
					ease: EASE,
				},
				INITIAL_DURATION + PAUSE_TIME,
			)
		setTl1(tl)
	}, [])

	useAnimation(() => {
		if (canPlay) {
			tl1?.play()
		} else {
			tl1?.seek(0).pause()
		}
	}, [canPlay, tl1])

	return (
		<>
			<Wrapper ref={topWrapper} viewBox="0 0 120 120" fill="none">
				<path
					d="M25.5734 67.7756C25.5205 67.7227 25.4488 67.6929 25.374 67.6929L24.64 67.6929C24.5651 67.6929 24.4934 67.7227 24.4405 67.7756L13.3257 78.8904L14.8813 80.446L23.9026 71.4247L23.9026 89.6929L26.1026 89.6929L26.1026 71.416L35.1186 80.432L36.6742 78.8764L25.5734 67.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M55.5734 85.7756C55.5205 85.7227 55.4488 85.6929 55.374 85.6929L54.64 85.6929C54.5651 85.6929 54.4934 85.7227 54.4405 85.7756L43.3257 96.8904L44.8813 98.446L53.9026 89.4247L53.9026 107.693L56.1026 107.693L56.1026 89.416L65.1186 98.432L66.6742 96.8764L55.5734 85.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M90.5734 73.7756C90.5205 73.7227 90.4488 73.6929 90.374 73.6929L89.64 73.6929C89.5651 73.6929 89.4934 73.7227 89.4405 73.7756L78.3257 84.8904L79.8813 86.446L88.9026 77.4247L88.9026 95.6929L91.1026 95.6929L91.1026 77.416L100.119 86.432L101.674 84.8764L90.5734 73.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M57.5734 48.7756C57.5205 48.7227 57.4488 48.6929 57.374 48.6929L56.64 48.6929C56.5651 48.6929 56.4934 48.7227 56.4405 48.7756L45.3257 59.8904L46.8813 61.446L55.9026 52.4247L55.9026 70.6929L58.1026 70.6929L58.1026 52.416L67.1186 61.432L68.6742 59.8764L57.5734 48.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M92.5734 36.7756C92.5205 36.7227 92.4488 36.6929 92.374 36.6929L91.64 36.6929C91.5651 36.6929 91.4934 36.7227 91.4405 36.7756L80.3257 47.8904L81.8813 49.446L90.9026 40.4247L90.9026 58.6929L93.1026 58.6929L93.1026 40.416L102.119 49.432L103.674 47.8764L92.5734 36.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M67.5734 12.7756C67.5205 12.7227 67.4488 12.6929 67.374 12.6929L66.64 12.6929C66.5651 12.6929 66.4934 12.7227 66.4405 12.7756L55.3257 23.8904L56.8813 25.446L65.9026 16.4247L65.9026 34.6929L68.1026 34.6929L68.1026 16.416L77.1186 25.432L78.6742 23.8764L67.5734 12.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M30.5734 24.7756C30.5205 24.7227 30.4488 24.6929 30.374 24.6929L29.64 24.6929C29.5651 24.6929 29.4934 24.7227 29.4405 24.7756L18.3257 35.8904L19.8813 37.446L28.9026 28.4247L28.9026 46.6929L31.1026 46.6929L31.1026 28.416L40.1186 37.432L41.6742 35.8764L30.5734 24.7756Z"
					fill={ARROW_COLOR}
				/>
			</Wrapper>
			<Wrapper ref={bottomWrapper} viewBox="0 0 120 120" fill="none">
				<path
					d="M25.5734 67.7756C25.5205 67.7227 25.4488 67.6929 25.374 67.6929L24.64 67.6929C24.5651 67.6929 24.4934 67.7227 24.4405 67.7756L13.3257 78.8904L14.8813 80.446L23.9026 71.4247L23.9026 89.6929L26.1026 89.6929L26.1026 71.416L35.1186 80.432L36.6742 78.8764L25.5734 67.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M55.5734 85.7756C55.5205 85.7227 55.4488 85.6929 55.374 85.6929L54.64 85.6929C54.5651 85.6929 54.4934 85.7227 54.4405 85.7756L43.3257 96.8904L44.8813 98.446L53.9026 89.4247L53.9026 107.693L56.1026 107.693L56.1026 89.416L65.1186 98.432L66.6742 96.8764L55.5734 85.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M90.5734 73.7756C90.5205 73.7227 90.4488 73.6929 90.374 73.6929L89.64 73.6929C89.5651 73.6929 89.4934 73.7227 89.4405 73.7756L78.3257 84.8904L79.8813 86.446L88.9026 77.4247L88.9026 95.6929L91.1026 95.6929L91.1026 77.416L100.119 86.432L101.674 84.8764L90.5734 73.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M57.5734 48.7756C57.5205 48.7227 57.4488 48.6929 57.374 48.6929L56.64 48.6929C56.5651 48.6929 56.4934 48.7227 56.4405 48.7756L45.3257 59.8904L46.8813 61.446L55.9026 52.4247L55.9026 70.6929L58.1026 70.6929L58.1026 52.416L67.1186 61.432L68.6742 59.8764L57.5734 48.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M92.5734 36.7756C92.5205 36.7227 92.4488 36.6929 92.374 36.6929L91.64 36.6929C91.5651 36.6929 91.4934 36.7227 91.4405 36.7756L80.3257 47.8904L81.8813 49.446L90.9026 40.4247L90.9026 58.6929L93.1026 58.6929L93.1026 40.416L102.119 49.432L103.674 47.8764L92.5734 36.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M67.5734 12.7756C67.5205 12.7227 67.4488 12.6929 67.374 12.6929L66.64 12.6929C66.5651 12.6929 66.4934 12.7227 66.4405 12.7756L55.3257 23.8904L56.8813 25.446L65.9026 16.4247L65.9026 34.6929L68.1026 34.6929L68.1026 16.416L77.1186 25.432L78.6742 23.8764L67.5734 12.7756Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M30.5734 24.7756C30.5205 24.7227 30.4488 24.6929 30.374 24.6929L29.64 24.6929C29.5651 24.6929 29.4934 24.7227 29.4405 24.7756L18.3257 35.8904L19.8813 37.446L28.9026 28.4247L28.9026 46.6929L31.1026 46.6929L31.1026 28.416L40.1186 37.432L41.6742 35.8764L30.5734 24.7756Z"
					fill={ARROW_COLOR}
				/>
			</Wrapper>
		</>
	)
}

const Wrapper = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`
