import * as RadioGroup from "@radix-ui/react-radio-group"
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive"
import { useState } from "react"
import styled, { css, keyframes } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

const items = [
	"QuickBooks",
	"Xero",
	"NetSuite",
	"Sage Intacct",
	"None",
	"Other",
]

export default function CurrentSoftware() {
	const [value, setValue] = useState<string>()

	return (
		<>
			<input type="hidden" name="accounting_software" value={value} />
			<RadioGroup.Root value={value} onValueChange={setValue}>
				<Items>
					{items.map((item) => (
						<Item key={item} value={item}>
							<Box>
								<Indicator>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="17"
										height="12"
										viewBox="0 0 17 12"
										fill="none"
									>
										<title>Checkmark</title>
										<path
											d="M0 6.24163L5.75837 12L16.1227 1.63507L14.4992 0L5.75837 8.74088L1.62341 4.61823L0 6.24163Z"
											fill="#142D25"
										/>
									</svg>
								</Indicator>
							</Box>
							{item}
						</Item>
					))}
				</Items>
			</RadioGroup.Root>
		</>
	)
}

const Items = styled.div`
  ${fresponsive(css`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 25px 50px;
    margin: 20px 0 0 15px;
    white-space: nowrap;
  `)}

  ${ftablet(css`
    grid-template-columns: 1fr 1fr;
    margin-top: 30px;
  `)}

  ${fmobile(css`
    margin-top: 24px;
    gap: 25px 20px;
  `)}
`

const Item = styled(RadioGroup.Item)`
  ${textStyles.titleL};
  ${fresponsive(css`
    color: ${colors.greenPastel01};
    display: flex;
    gap: 15px;
    align-items: center;
    cursor: pointer;
  `)}

  ${fmobile(css`
    ${textStyles.titleS}
  `)}
`

const Box = styled.div`
  ${fresponsive(css`
    border: 1.5px solid ${colors.greenGray02};
    width: 24px;
    height: 24px;
    stroke-width: 1.5px;
    border-radius: 4px;
    transition: background 0.4s;
    overflow: clip;
  `)}

  ${Item}[data-state="checked"] & {
    background: ${colors.greenSaturated02};
  }
`

const slideDown = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(150%);
  }
`

const slideUp = keyframes`
  0% {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0);
  }
`

const Indicator = styled(RadioGroup.Indicator)`
  ${fresponsive(css`
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;

    svg {
      width: 16px;
      translate: 1px 1px;
    }
  `)}

  &[data-state="checked"] {
    animation: ${slideUp} 0.4s forwards;
  }

  &[data-state="unchecked"] {
    animation: ${slideDown} 0.4s forwards;
  }
`
