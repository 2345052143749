import Secondary from "components/Buttons/Secondary";
import { useNavConfig } from "components/Providers/Nav";
import gsap from "gsap";
import { MorphSVGPlugin } from "gsap/all";
import { ReactComponent as CampfireLogo } from "images/global/logoFull.svg";
import UniversalLink from "library/Loader/UniversalLink";
import { DesktopTabletOnly, MobileOnly } from "library/breakpointUtils";
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive";
import useAnimation from "library/useAnimation";
import useMedia from "library/useMedia";
import { useEffect, useRef } from "react";
import styled, { css } from "styled-components";
import colors from "styles/colors";
import textStyles from "styles/text";
import links from "utils/links";

import { isBrowser } from "library/functions";
import type { HamburgerSectionLinks } from ".";
import LinkButton from "../Buttons/LinkButton";

gsap.registerPlugin(MorphSVGPlugin);

const X_1 = "M2.92871 15.1421L17.0708 0.999954";
const X_2 = "M2.92871 15.1421L17.0708 0.999954";
const X_3 = "M2.92871 1.07104L17.0708 15.2132";
const HAMBURGER_1 = "M0 4H20";
const HAMBURGER_2 = "M0 9H20";
const HAMBURGER_3 = "M0 14H20";
const EASE = "power1.inOut";

export default function ResponsiveDropDown({
  sectionData,
  open,
  setOpen,
}: {
  sectionData: HamburgerSectionLinks[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const hamburger1 = useRef<SVGPathElement>(null);
  const hamburger2 = useRef<SVGPathElement>(null);
  const hamburger3 = useRef<SVGPathElement>(null);
  const scrollerRef = useRef<HTMLDivElement>(null);

  const mobile = useMedia(false, false, false, true);
  const { color } = useNavConfig();

  useEffect(() => {
    const handleSetOpen = () => {
      setOpen(false);
    };

    window.addEventListener("scroll", handleSetOpen);

    return () => {
      window.removeEventListener("scroll", handleSetOpen);
    };
  }, [setOpen]);

  const handleOpenClose = () => {
    setOpen(!open);
  };

  useAnimation(() => {
    if (!isBrowser()) return;

    gsap.to(dropdownRef.current, {
      autoAlpha: open ? 1 : 0,
      duration: 0.3,
      ease: EASE,
    });
    const tl = gsap.timeline();
    if (!scrollerRef.current) return;
    gsap.set(scrollerRef.current, { scrollTop: 0 });
    if (open) {
      tl.to(
        hamburger1.current,
        { duration: 0.3, morphSVG: X_1, ease: EASE },
        0,
      );
      tl.to(
        hamburger2.current,
        { duration: 0.3, morphSVG: X_2, ease: EASE },
        0,
      );
      tl.to(
        hamburger3.current,
        { duration: 0.3, morphSVG: X_3, ease: EASE },
        0,
      );
      tl.to(
        "#main-blur",
        {
          backdropFilter: "blur(5px)",
          duration: 0.3,
          ease: EASE,
        },
        0,
      );
      tl.to(
        dropdownRef.current,
        {
          autoAlpha: 1,
          duration: 0.3,
        },
        0,
      );
    } else {
      tl.to(
        hamburger1.current,
        { duration: 0.3, morphSVG: HAMBURGER_1, ease: EASE },
        0,
      );
      tl.to(
        hamburger2.current,
        { duration: 0.3, morphSVG: HAMBURGER_2, ease: EASE },
        0,
      );
      tl.to(
        hamburger3.current,
        { duration: 0.3, morphSVG: HAMBURGER_3, ease: EASE },
        0,
      );
      tl.to(
        dropdownRef.current,
        {
          autoAlpha: 0,
          duration: 0.3,
        },
        0,
      );
      tl.to(
        "#main-blur",
        {
          backdropFilter: "blur(0px)",
          duration: 0.3,
          ease: EASE,
        },
        0,
      );
    }
  }, [open]);

  const SectionColumns = sectionData.map((columnData) => {
    const { links: headerLinks, section, kicker } = columnData;

    return (
      <Column key={section}>
        <Kicker>{kicker ?? section}</Kicker>
        {headerLinks.map((link, index) => {
          const { name, href, description } = link;
          return (
            <LinkContainer key={link.href}>
              <StyledLinkButton
                className="link_button-tablet"
                to={href}
                variant="arrow"
                dark
              >
                {name}
              </StyledLinkButton>
              {description && <Description>{description}</Description>}
            </LinkContainer>
          );
        })}
      </Column>
    );
  });

  return (
    <DropdownWrapper>
      <MobileRow>
        <Hamburger
          className="dropdown-responsive-menu"
          ariaLabel="open and close menu"
          type="button"
          variant={open ? "gray" : color}
          onClick={handleOpenClose}
        >
          <StyledHamburger
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              ref={hamburger1}
              d="M0 4H20"
              stroke="black"
              strokeWidth="1.25"
            />
            <path
              ref={hamburger2}
              d="M0 9H20"
              stroke="black"
              strokeWidth="1.25"
            />
            <path
              ref={hamburger3}
              d="M0 14H20"
              stroke="black"
              strokeWidth="1.25"
            />
          </StyledHamburger>
        </Hamburger>
      </MobileRow>
      <Dropdown
        open={open}
        ref={dropdownRef}
        role="presentation"
        // Close dropdown when clicking link/button within
        // This ensures the menu closes when e.g. opening the contact form
        onClick={(e) => {
          if (!(e.target instanceof Element)) return;
          // If we clicked on an anchor or button
          // If we clicked on a descendant of an anchor or button
          if (
            e.target instanceof HTMLAnchorElement ||
            e.target instanceof HTMLButtonElement ||
            Boolean(e.target.closest("a")) ||
            Boolean(e.target.closest("button"))
          ) {
            setOpen(false);
          }
        }}
      >
        <TopRow>
          <MobileOnly>
            <UniversalLink to={links.home}>
              <Logo />
            </UniversalLink>
          </MobileOnly>
          <DesktopTabletOnly>
            <LinkButton to={links.logIn} variant="empty" dark>
              Log In
            </LinkButton>
          </DesktopTabletOnly>
        </TopRow>
        <MobileScroller ref={scrollerRef}>
          <MobileInner>
            {mobile ? SectionColumns.slice(0, 3) : SectionColumns}
            <TwoColumns>{SectionColumns.slice(3, 5)}</TwoColumns>
          </MobileInner>
        </MobileScroller>
        <MobileBottomRow>
          <SignIn className="mobile-sign-in" to={links.logIn} variant="gray">
            Log In
          </SignIn>
          <Secondary to={links.getStarted} variant={color}>
            Get Started
          </Secondary>
        </MobileBottomRow>
      </Dropdown>
    </DropdownWrapper>
  );
}

const Column = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${colors.charcoal200}50;

  ${fresponsive(css`
    width: 100%;
    gap: 25px;
    padding: 15px 38px 35px;
  `)}
`;

const Kicker = styled.div`
  color: ${colors.charcoal300};
  ${textStyles.bodyXS};
`;

const LinkContainer = styled.div``;

const StyledLinkButton = styled(LinkButton)`
  display: flex;
  justify-content: space-between;

  ${ftablet(css`
    &.link-button-text {
      ${textStyles.bodyXL};
    }

    width: 398px;
  `)}

  ${fmobile(css`
    &.link-button-text {
      ${textStyles.bodyL};
    }

    ${textStyles.bodyL};
    width: 100%;
  `)}
`;

const Description = styled.p`
  ${textStyles.bodyXS};
  color: ${colors.charcoal300};
  ${fresponsive(css`
    width: 183px;
  `)}
`;

const DropdownWrapper = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  height: auto;
  border-radius: 30px;
  ${fmobile(css`
    width: 100%;
  `)}
`;

const MobileRow = styled.div`
  ${fmobile(css`
    position: relative;
    display: flex;
    justify-content: space-between;
  `)}
`;

const Hamburger = styled(Secondary)`
  ${fresponsive(css`
    div {
      padding: 16px 24px;
    }

    span {
      display: grid;
      place-items: center;
    }
  `)}

  ${ftablet(css`
    div {
      padding: 22px 26px;
    }
  `)}

  ${fmobile(css`
    div {
      padding: 22px 26px;
    }
  `)}
`;

const StyledHamburger = styled.svg`
  ${fresponsive(css`
    width: 20px;
    height: 18px;
  `)}
`;

const Dropdown = styled.div<{ open: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background-color: ${colors.white};
  opacity: 0;
  overflow: clip;

  ${fresponsive(css`
    top: -16px;
    right: -16px;
    border-radius: 20px;
    height: auto;
    width: 474px;
    max-height: 80vh;
    box-shadow: 0 8px 10px 0 rgb(0 0 0 / 10%);
  `)}

  ${ftablet(css`
    top: -16px;
    right: -16px;
    border-radius: 20px;
    height: auto;
    width: 474px;
    max-height: unset;
  `)}

  ${({ open }) =>
    fmobile(css`
      top: -10px;
      right: -10px;
      border-radius: 30px;
      height: 98dvh;
      width: 355px;
      overflow-y: hidden;

      svg {
        path:not(:last-of-type) {
          fill: ${open ? colors.greenBlack : colors.white};
        }
      }
    `)}
`;

const TopRow = styled.div`
  display: flex;
  background-color: ${colors.white};
  ${fresponsive(css`
    position: absolute;
    top: 0;
    z-index: 20;
    padding: 30px 38px;
    border-radius: 20px 20px 0 0;
    display: flex;
    justify-content: space-between;
    height: 100px;
    width: 100%;
  `)}

  ${fmobile(css`
    position: absolute;
    top: 0;
    padding: 30px 38px 30px 10px;
    display: flex;
    justify-content: space-between;
    height: 84px;
    width: 100%;
  `)}
`;

const Logo = styled(CampfireLogo)`
  path {
    fill: ${colors.white};

    &:last-of-type {
      fill: ${colors.orangeSaturated01};
    }
  }

  ${fresponsive(css`
    width: 182px;
    height: auto;
  `)}

  ${fmobile(css`
    width: 140px;
  `)}
`;

const MobileScroller = styled.div`
  position: relative;
  overflow-y: scroll;
  height: 100%;

  ${ftablet(css`
    overflow-y: unset;
    height: unset;
  `)}
`;

const MobileInner = styled.div`
  ${fresponsive(css`
    position: relative;
    padding: 100px 0 0;
  `)}
  ${fmobile(css`
    position: relative;
    padding: 84px 0;
  `)}
`;

const TwoColumns = styled(MobileOnly)`
  ${fmobile(css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: 0;
    padding: 0 18px 20px 40px;
    border-top: 1px solid ${colors.charcoal200}50;

    ${Column} {
      border-top: none;
      padding: 12px 0 0;
      width: fit-content;
    }
  `)}
`;

const MobileBottomRow = styled.div`
  display: none;
  ${fmobile(css`
    position: absolute;
    display: flex;
    justify-content: center;
    gap: 14px;
    align-items: center;
    padding: 0 19px;
    bottom: 0;
    z-index: 20;
    background-color: ${colors.white};
    height: 84px;
    width: 100%;
  `)}
`;

const SignIn = styled(Secondary)`
  ${fmobile(css`
    width: 152px;
  `)}
`;
