import { ContactLink } from "components/Contact/ContactLink";
import { ReactComponent as Arrow } from "images/global/buttonArrow.svg";
import type { UniversalLinkProps } from "library/Loader/UniversalLink";
import { fresponsive } from "library/fullyResponsive";
import styled, { css } from "styled-components";
import colors from "styles/colors";
import { eases } from "styles/eases";
import media from "styles/media";
import textStyles from "styles/text";

const BUTTON_EASE = eases.campfire.in;

export default function Primary({
  children,
  className = "",
  ...props
}: UniversalLinkProps) {
  return (
    <Wrapper className={className} {...props}>
      <ButtonText>{children}</ButtonText>
      <AnimatingCircleBottom
        $backgroundColor={`${colors.orangeSaturated01}50`}
      />
      <AnimatingCircleMiddle $backgroundColor={colors.orangeSaturated02} />
      <AnimatingCircleTop $backgroundColor={colors.orangeSaturated01} />
      <StaticCircle>
        <ArrowIcon />
      </StaticCircle>
    </Wrapper>
  );
}

const ButtonText = styled.span`
  position: relative;
  z-index: 3;
  transition: color 0.3s ${BUTTON_EASE} 0.2s;
`;

const animatingCircleProps = css`
  position: absolute;
  border-radius: 50%;
  translate: 50% 0;
  z-index: 1;
  aspect-ratio: 1;

  ${fresponsive(css`
    width: 36px;
    right: ${19.5 + 36 / 2}px;
  `)}
`;

const AnimatingCircleBottom = styled.div<{ $backgroundColor: string }>`
  ${animatingCircleProps};
  transition: all 0.3s ${BUTTON_EASE} 0.1s;
  background: ${({ $backgroundColor }) => $backgroundColor};
`;

const AnimatingCircleMiddle = styled.div<{ $backgroundColor: string }>`
  ${animatingCircleProps};
  transition: all 0.3s ${BUTTON_EASE} 0.05s;
  background: ${({ $backgroundColor }) => $backgroundColor};
`;

const AnimatingCircleTop = styled.div<{ $backgroundColor: string }>`
  ${animatingCircleProps};
  transition: all 0.3s ${BUTTON_EASE} 0s;
  background: ${({ $backgroundColor }) => $backgroundColor};
`;

const ArrowIcon = styled(Arrow)`
  ${fresponsive(css`
    position: relative;
    width: 16px;
    height: 16px;
    z-index: 3;
    transition: transform 0.3s ${BUTTON_EASE};
  `)}
`;

const Wrapper = styled(ContactLink)`
  cursor: pointer;
  ${textStyles.titleL};
  color: ${colors.charcoal700};
  width: fit-content;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  background: ${colors.greenSaturated02};
  overflow: clip;
  ${fresponsive(css`
    padding: 16px 19.5px 16px 24.8px;
    border-radius: 99vw;
    gap: 56px;
  `)};

  ${media.hover} {
    &:hover {
      ${ArrowIcon} {
        transform: translateX(4px);
        transition: transform 0.3s ${BUTTON_EASE};
      }

      ${AnimatingCircleBottom} {
        width: 200%;
        transition: all 0.4s ${BUTTON_EASE};
      }

      ${AnimatingCircleMiddle} {
        width: 200%;
        transition: all 0.4s ${BUTTON_EASE};
        transition-delay: 0.05s;
      }

      ${AnimatingCircleTop} {
        width: 200%;
        transition: all 0.4s ${BUTTON_EASE};
        transition-delay: 0.1s;
      }

      ${ButtonText} {
        color: ${colors.white};
        transition: color 0.3s ${BUTTON_EASE};
      }
    }
  }
`;

const StaticCircle = styled.div`
  ${fresponsive(css`
    width: 36px;
    height: 36px;
    position: relative;
    display: grid;
    place-items: center;
    z-index: 2;
    background: ${colors.orangeSaturated01};
    border-radius: 50%;
  `)}
`;
