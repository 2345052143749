import gsap from "gsap";
import {
  registerTransition,
  unregisterTransition,
} from "library/Loader/TransitionUtils";
import { useCallback, useEffect, useRef } from "react";
import styled from "styled-components";
import colors from "styles/colors";

export default function Transition() {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const fadeIn = useCallback(() => {
    gsap.fromTo(
      wrapperRef.current,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.5,
        ease: "power1.in",
      },
    );
  }, []);

  const fadeOut = useCallback(() => {
    gsap.to(wrapperRef.current, {
      duration: 0.25,
      onComplete: () => {
        gsap.to(wrapperRef.current, {
          autoAlpha: 0,
          ease: "power1.out",
          duration: 0.5,
        });
      },
    });
  }, []);

  useEffect(() => {
    registerTransition("fade", {
      in: fadeIn,
      out: fadeOut,
      inDuration: 0.5,
      outDuration: 0.75,
    });

    return () => {
      unregisterTransition("fade", [fadeIn, fadeOut]);
    };
  }, [fadeIn, fadeOut]);

  return <Wrapper ref={wrapperRef} />;
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.greenDark01};
  z-index: 100;
  opacity: 0;
  pointer-events: none;
`;
