import { useNavConfig } from "components/Providers/Nav";
import gsap from "gsap";
import { ScrollSmoother } from "gsap/all";
import { ReactComponent as MenuTopSVG } from "images/header/menuTop.svg";
import { fresponsive } from "library/fullyResponsive";
import useAnimation from "library/useAnimation";
import type { Dispatch, SetStateAction } from "react";
import { useRef, useState } from "react";
import styled, { css } from "styled-components";
import colors from "styles/colors";

import LinkButton from "../Buttons/LinkButton";
import type { SectionLinks } from "../Header/Desktop";
import AnimatedContent from "./AnimatedContent";
import ColumnContent from "./ColumnContent";

const BUTTON_EASE = "power1.inOut";

export default function DropDown({
  sectionData,
  setCanPlayIndex,
  className = "",
}: {
  sectionData: SectionLinks;
  setCanPlayIndex?: Dispatch<SetStateAction<number>>;
  className?: string;
}) {
  const { links, section, animationHandler, columns } = sectionData;
  const [activeIndex, setActiveIndex] = useState(-1);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const initial = useRef(true);
  const lastActiveIndex = useRef(0);

  const backgroundcolor =
    links?.[activeIndex === -1 ? 0 : activeIndex]?.background ??
    colors.greenPastel02;
  const textColor =
    links?.[activeIndex === -1 ? 0 : activeIndex]?.color ?? colors.greenGray02;

  const { menuDark } = useNavConfig();

  const handleOpen = () => {
    ScrollSmoother.get()?.paused(true);
    const tl = gsap.timeline();
    tl.set(dropdownRef.current, { pointerEvents: "all" }, 0);

    tl.to(
      dropdownRef.current,
      {
        autoAlpha: 1,
        x: 0,
        duration: 0.15,
        ease: BUTTON_EASE,
      },
      0,
    );
    tl.to(
      "#main-blur",
      {
        backdropFilter: "blur(5px)",
        duration: 0.15,
        ease: BUTTON_EASE,
      },
      0,
    );
  };

  const handleClose = () => {
    ScrollSmoother.get()?.paused(false);
    const tl = gsap.timeline();
    tl.set(dropdownRef.current, { pointerEvents: "none" }, 0);

    tl.to(
      dropdownRef.current,
      {
        autoAlpha: 0,
        x: -100,
        duration: 0.15,
        ease: BUTTON_EASE,
      },
      0,
    );
    tl.to(
      "#main-blur",
      {
        backdropFilter: "blur(0px)",
        duration: 0.15,
        ease: BUTTON_EASE,
      },
      0,
    );
  };

  useAnimation(() => {
    // Handles the inital state when nothing is hovered when first opening the dropdown
    if (initial.current) {
      gsap.set(dropdownRef.current, {
        autoAlpha: 0,
        x: -100,
      });

      gsap.set(`.icon-${section}-${activeIndex}`, {
        opacity: 0.5,
      });

      initial.current = false;
    }
    const tl = gsap.timeline();

    // We want the behavior of index 0 to be different when the activeIndex is -1
    const handleEmptyIndex = activeIndex === -1;

    // Animating the image/icon
    if (handleEmptyIndex) {
      tl.to(
        `.icon-${section}-${lastActiveIndex.current}`,
        {
          opacity: 0,
          duration: 0.3,
          ease: BUTTON_EASE,
        },
        0,
      );
      tl.to(
        `.icon-${section}-0`,
        {
          opacity: 0.5,
          duration: 0.3,
          ease: BUTTON_EASE,
        },
        0,
      );
      return;
    }

    tl.to(
      `.icon-${section}-${lastActiveIndex.current}`,
      {
        opacity: 0,
        duration: 0.3,
        ease: BUTTON_EASE,
      },
      0,
    );
    tl.to(
      `.icon-${section}-${activeIndex}`,
      {
        opacity: 1,
        duration: 0.3,
        ease: BUTTON_EASE,
        onStart: () => {
          lastActiveIndex.current = activeIndex;
        },
      },
      0,
    );
  }, [activeIndex, section]);

  return (
    <DropdownWrapper
      className={className}
      onMouseLeave={handleClose}
      onMouseEnter={handleOpen}
      onClick={handleClose}
      role="presentation"
      $backgroundColor={backgroundcolor}
    >
      <LinkButton dark={menuDark} type="button" variant="dropdown">
        {section}
      </LinkButton>
      <Dropdown ref={dropdownRef} $backgroundColor={backgroundcolor}>
        <Connector>
          <MenuTop />
        </Connector>
        {animationHandler && links ? (
          <AnimatedContent
            section={section}
            links={links}
            color={textColor}
            setActiveIndex={setActiveIndex}
            setCanPlayIndex={setCanPlayIndex}
          />
        ) : (
          <ColumnContent columns={columns} />
        )}
      </Dropdown>
    </DropdownWrapper>
  );
}

const MenuTop = styled(MenuTopSVG)`
  position: absolute;
  ${fresponsive(css`
    width: 38px;
    height: 9px;
    left: 32px;
    bottom: 0;
  `)}
`;

const DropdownWrapper = styled.div<{ $backgroundColor: string }>`
  position: relative;
  display: flex;
  width: fit-content;
  height: fit-content;

  ${MenuTop} {
    path {
      fill: ${({ $backgroundColor }) => $backgroundColor};
    }
  }
`;

const Dropdown = styled.div<{
  $backgroundColor: string;
}>`
  position: absolute;
  display: flex;
  z-index: 100;
  background-color: ${({ $backgroundColor }) => $backgroundColor};

  ${fresponsive(css`
    top: 40px;
    left: -20px;
    padding: 20px;
    border-radius: 20px;
    gap: 50px;
    height: auto;
  `)}
`;

const Connector = styled.div`
  position: absolute;
  ${fresponsive(css`
    width: calc(100% - 20px);
    height: 25px;
    top: -25px;
    left: 20px;
  `)}
`;
