const TEMP = "#open-contact"

const routes = {
	home: "/",
	company: "/company/",
	revenueAutomation: "/revenue-automation/",
	coreAccounting: "/core-accounting/",
	blog: "/blog/",
	terms: "/terms-and-conditions/",
	privacy: "/privacy-policy/",
	careers:
		"https://meetcampfire.notion.site/Campfire-careers-fe583ee7025a4789b650d74bcd86aae4",
	customers: "/customers/",
	community: "/blog/campsites-the-community-for-accounting-finance-leaders/",
	developers: "https://api.meetcampfire.com/api/docs/",
	startups: "/solutions/startups/",
	midMarket: "/solutions/mid-market/",
	accountingFirms: "/solutions/accounting-firms/",
	contact: "contact",
	getStarted: "demo",
	caseStudies: "/blog?category=Case Study",
} as const

const social = {
	twitter: "https://twitter.com/meet_campfire",
	linkedin: "https://www.linkedin.com/company/meetcampfire",
} as const

const sections = {
	// TODO add or delete
} as const

const email = {
	// TODO add or delete
} as const

const partners = {
	// TODO add or delete
} as const

const customers = {
	// TODO add or delete
} as const

const links = {
	...routes,
	...social,
	...sections,
	...customers,
	...email,
	...partners,
	logIn: "https://app.meetcampfire.com/",
	todo: "/",
} as const

export default links
