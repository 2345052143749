import { ContactLink } from "components/Contact/ContactLink";
import { ReactComponent as ArroSVG } from "images/global/buttonArrow.svg";
import { ReactComponent as ChevronSVG } from "images/global/chevron.svg";
import type { UniversalLinkProps } from "library/Loader/UniversalLink";
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive";
import useMedia from "library/useMedia";
import type { ReactNode } from "react";
import styled, { css } from "styled-components";
import colors from "styles/colors";
import { eases } from "styles/eases";
import media from "styles/media";
import textStyles from "styles/text";

const BUTTON_EASE = eases.campfire.in;

type Linkcolors = "arrow" | "dropdown" | "empty";

export default function LinkButton({
  children,
  dark = false,
  variant = "empty",
  className = "",
  handleEnter,
  handleLeave,
  ...props
}: {
  children: ReactNode;
  variant?: Linkcolors;
  className?: string;
  dark?: boolean;
  handleEnter?: () => void;
  handleLeave?: () => void;
} & UniversalLinkProps) {
  const canHover = useMedia(true, true, false, false);
  const showIconContainer = variant !== "empty";

  return (
    <Wrapper
      className={className}
      {...props}
      onMouseEnter={() => {
        handleEnter?.();
      }}
      onMouseLeave={() => {
        handleLeave?.();
      }}
    >
      <TextLayer $dark={dark}>
        <ButtonText className="link-button-text">{children}</ButtonText>
        {canHover && (
          <ButtonTextLower className="link-button-text">
            {children}
          </ButtonTextLower>
        )}
      </TextLayer>

      {showIconContainer && (
        <IconContainer $dark={dark}>
          {variant === "arrow" && <Arrow $staticArrow={!canHover} />}
          {variant === "dropdown" && canHover && <Chevron />}
        </IconContainer>
      )}
    </Wrapper>
  );
}

const ButtonText = styled.div`
  position: relative;
  z-index: 3;
  transition: transform 0.3s ${BUTTON_EASE};
  white-space: nowrap;
`;

const ButtonTextLower = styled(ButtonText)``;

const Arrow = styled(ArroSVG)<{ $staticArrow: boolean }>`
  width: 100%;
  height: 100%;
  transition: all 0.3s ${BUTTON_EASE};
  opacity: ${({ $staticArrow }) => ($staticArrow ? 1 : 0)};
`;

const Chevron = styled(ChevronSVG)`
  transition: transform 0.3s ${BUTTON_EASE};
`;

const Wrapper = styled(ContactLink)`
  ${textStyles.titleL};
  color: ${colors.charcoal700};
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  position: relative;

  ${fresponsive(css`
    gap: 5px;
  `)};

  ${media.desktop} {
    &:hover {
      ${ButtonText} {
        transform: translateY(-100%);
        transition: transform 0.3s ${BUTTON_EASE};
      }
      ${ButtonTextLower} {
        transform: translateY(-100%);
        transition: transform 0.3s ${BUTTON_EASE};
      }

      ${Arrow} {
        transform: translateX(5px);
        opacity: 1;
        transition: all 0.3s ${BUTTON_EASE};
      }

      ${Chevron} {
        transform: rotateX(180deg);
        transition: transform 0.3s ${BUTTON_EASE};
      }
    }
  }

  ${media.fullWidth} {
    &:hover {
      ${ButtonText} {
        transform: translateY(-100%);
        transition: transform 0.3s ${BUTTON_EASE};
      }
      ${ButtonTextLower} {
        transform: translateY(-100%);
        transition: transform 0.3s ${BUTTON_EASE};
      }

      ${Arrow} {
        transform: translateX(5px);
        opacity: 1;
        transition: all 0.3s ${BUTTON_EASE};
      }

      ${Chevron} {
        transform: rotateX(180deg);
        transition: transform 0.3s ${BUTTON_EASE};
      }
    }
  }
`;

const TextLayer = styled.div<{ $dark: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: clip;
  z-index: 3;
  color: ${({ $dark }) => ($dark ? colors.greenBlack : colors.white)};

  ${fresponsive(css`
    ${textStyles.bodyS};
    height: 22px;
  `)};

  ${ftablet(css`
    ${textStyles.bodyL};
    height: auto;
  `)};

  ${fmobile(css`
    ${textStyles.bodyXL};
    height: auto;
  `)};
`;

const IconContainer = styled.div<{ $dark: boolean }>`
  position: relative;

  ${fresponsive(css`
    width: 12px;
    height: 12px;
    margin-bottom: 2px;
  `)};

  svg {
    display: block;

    path {
      fill: ${({ $dark }) => ($dark ? colors.greenBlack : colors.white)};
    }
  }
  ${ftablet(css`
    width: 18px;
    height: 18px;
    margin-bottom: unset;
  `)};

  ${fmobile(css`
    margin-bottom: unset;
    width: 14px;
    height: 14px;
  `)};
`;
