import gsap from "gsap"
import useAnimation from "library/useAnimation"
import { getResponsivePixels } from "library/viewportUtils"
import { useState } from "react"
import styled from "styled-components"
import colors from "styles/colors"

const ARROW_COLOR = colors.orangeDark01

const INITIAL_DURATION = 1
const PAUSE_TIME = 0.8
const EASE = "power3.inOut"

export default function TriangleRowsArrows({ canPlay }: { canPlay: boolean }) {
	const [tl1, setTl1] = useState<gsap.core.Timeline | null>(null)
	const [wrapperRef, setWrapperRef] = useState<SVGSVGElement | null>(null)

	useAnimation(
		() => {
			const firstRow1 = ".first-row-1"
			const firstRow2 = ".first-row-2"
			const firstRow3 = ".first-row-3"
			const firstGroup = ".first"
			const secondRow1 = ".second-row-1"
			const secondRow2 = ".second-row-2"
			const secondRow3 = ".second-row-3"
			const secondGroup = ".second"

			gsap.set(secondRow1, { x: "-320%", autoAlpha: 0 })
			gsap.set(secondRow2, { x: "160%", autoAlpha: 0 })
			gsap.set(secondRow3, { x: "-120%", autoAlpha: 0 })

			const tl = gsap.timeline({
				repeat: -1,
				paused: true,
				repeatDelay: PAUSE_TIME,
			})

			tl.to(
				firstGroup,
				{
					y: `-=${getResponsivePixels(130)}`,
					autoAlpha: 0,
					duration: INITIAL_DURATION,
					ease: EASE,
				},
				0,
			)
				.to(
					[secondRow1, secondRow2, secondRow3],
					{
						x: 0,
						autoAlpha: 1,
						duration: INITIAL_DURATION,
						ease: EASE,
					},
					0,
				)
				.to(
					secondGroup,
					{
						y: `-=${getResponsivePixels(130)}`,
						autoAlpha: 0,
						duration: INITIAL_DURATION,
						ease: EASE,
					},
					INITIAL_DURATION + PAUSE_TIME,
				)
				.to(
					firstRow1,
					{
						y: 0,
						autoAlpha: 0,
						x: "-320%",
						duration: 0,
					},
					INITIAL_DURATION,
				)
				.to(
					firstRow2,
					{
						y: 0,
						autoAlpha: 0,
						x: "160%",
						duration: 0,
					},
					INITIAL_DURATION,
				)
				.to(
					firstRow3,
					{
						y: 0,
						autoAlpha: 0,
						x: "-120%",
						duration: 0,
					},
					INITIAL_DURATION,
				)
				.to(
					[firstRow1, firstRow2, firstRow3],
					{
						x: 0,
						autoAlpha: 1,
						duration: INITIAL_DURATION,
						ease: EASE,
					},
					INITIAL_DURATION + PAUSE_TIME,
				)

			setTl1(tl)
		},
		[],
		{ scope: wrapperRef },
	)

	useAnimation(() => {
		if (canPlay) {
			tl1?.play()
		} else {
			tl1?.seek(0).pause()
		}
	}, [canPlay, tl1])

	return (
		<Wrapper
			ref={setWrapperRef}
			viewBox="0 0 120 120"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				className="first first-row-1"
				d="M60.8031 13.0827C60.8559 13.0298 60.9277 13.0001 61.0025 13.0001L61.7365 13.0001C61.8113 13.0001 61.8831 13.0298 61.936 13.0827L73.0508 24.1976L71.4951 25.7532L62.4739 16.7319L62.4739 35.0001L60.2739 35.0001L60.2739 16.7232L51.2579 25.7392L49.7022 24.1835L60.8031 13.0827Z"
				fill={ARROW_COLOR}
			/>
			<g className="first first-row-2">
				<path
					d="M42.8031 46.0827C42.8559 46.0298 42.9277 46.0001 43.0025 46.0001L43.7365 46.0001C43.8113 46.0001 43.8831 46.0298 43.936 46.0827L55.0508 57.1976L53.4951 58.7532L44.4739 49.7319L44.4739 68.0001L42.2739 68.0001L42.2739 49.7232L33.2579 58.7392L31.7022 57.1835L42.8031 46.0827Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M76.8031 46.0827C76.8559 46.0298 76.9277 46.0001 77.0025 46.0001L77.7365 46.0001C77.8113 46.0001 77.8831 46.0298 77.936 46.0827L89.0508 57.1976L87.4951 58.7532L78.4739 49.7319L78.4739 68.0001L76.2739 68.0001L76.2739 49.7232L67.2579 58.7392L65.7022 57.1835L76.8031 46.0827Z"
					fill={ARROW_COLOR}
				/>
			</g>
			<g className="first first-row-3">
				<path
					className="row-one"
					d="M25.8031 78.0827C25.8559 78.0298 25.9277 78.0001 26.0025 78.0001L26.7365 78.0001C26.8113 78.0001 26.8831 78.0298 26.936 78.0827L38.0508 89.1976L36.4951 90.7532L27.4739 81.7319L27.4739 100L25.2739 100L25.2739 81.7232L16.2579 90.7392L14.7022 89.1835L25.8031 78.0827Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M59.8031 78.0827C59.8559 78.0298 59.9277 78.0001 60.0025 78.0001L60.7365 78.0001C60.8113 78.0001 60.8831 78.0298 60.936 78.0827L72.0508 89.1976L70.4951 90.7532L61.4739 81.7319L61.4739 100L59.2739 100L59.2739 81.7232L50.2579 90.7392L48.7022 89.1835L59.8031 78.0827Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M93.8031 78.0827C93.8559 78.0298 93.9277 78.0001 94.0025 78.0001L94.7365 78.0001C94.8113 78.0001 94.8831 78.0298 94.936 78.0827L106.051 89.1976L104.495 90.7532L95.4739 81.7319L95.4739 100L93.2739 100L93.2739 81.7232L84.2579 90.7392L82.7022 89.1835L93.8031 78.0827Z"
					fill={ARROW_COLOR}
				/>
			</g>

			<path
				className="second second-row-1"
				d="M60.8031 13.0827C60.8559 13.0298 60.9277 13.0001 61.0025 13.0001L61.7365 13.0001C61.8113 13.0001 61.8831 13.0298 61.936 13.0827L73.0508 24.1976L71.4951 25.7532L62.4739 16.7319L62.4739 35.0001L60.2739 35.0001L60.2739 16.7232L51.2579 25.7392L49.7022 24.1835L60.8031 13.0827Z"
				fill={ARROW_COLOR}
			/>
			<g className="second second-row-2">
				<path
					d="M42.8031 46.0827C42.8559 46.0298 42.9277 46.0001 43.0025 46.0001L43.7365 46.0001C43.8113 46.0001 43.8831 46.0298 43.936 46.0827L55.0508 57.1976L53.4951 58.7532L44.4739 49.7319L44.4739 68.0001L42.2739 68.0001L42.2739 49.7232L33.2579 58.7392L31.7022 57.1835L42.8031 46.0827Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M76.8031 46.0827C76.8559 46.0298 76.9277 46.0001 77.0025 46.0001L77.7365 46.0001C77.8113 46.0001 77.8831 46.0298 77.936 46.0827L89.0508 57.1976L87.4951 58.7532L78.4739 49.7319L78.4739 68.0001L76.2739 68.0001L76.2739 49.7232L67.2579 58.7392L65.7022 57.1835L76.8031 46.0827Z"
					fill={ARROW_COLOR}
				/>
			</g>
			<g className="second second-row-3">
				<path
					className="row-one"
					d="M25.8031 78.0827C25.8559 78.0298 25.9277 78.0001 26.0025 78.0001L26.7365 78.0001C26.8113 78.0001 26.8831 78.0298 26.936 78.0827L38.0508 89.1976L36.4951 90.7532L27.4739 81.7319L27.4739 100L25.2739 100L25.2739 81.7232L16.2579 90.7392L14.7022 89.1835L25.8031 78.0827Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M59.8031 78.0827C59.8559 78.0298 59.9277 78.0001 60.0025 78.0001L60.7365 78.0001C60.8113 78.0001 60.8831 78.0298 60.936 78.0827L72.0508 89.1976L70.4951 90.7532L61.4739 81.7319L61.4739 100L59.2739 100L59.2739 81.7232L50.2579 90.7392L48.7022 89.1835L59.8031 78.0827Z"
					fill={ARROW_COLOR}
				/>
				<path
					d="M93.8031 78.0827C93.8559 78.0298 93.9277 78.0001 94.0025 78.0001L94.7365 78.0001C94.8113 78.0001 94.8831 78.0298 94.936 78.0827L106.051 89.1976L104.495 90.7532L95.4739 81.7319L95.4739 100L93.2739 100L93.2739 81.7232L84.2579 90.7392L82.7022 89.1835L93.8031 78.0827Z"
					fill={ARROW_COLOR}
				/>
			</g>
		</Wrapper>
	)
}

const Wrapper = styled.svg`
  height: 100%;
  width: 100%;
`
