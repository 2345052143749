import * as Form from "@radix-ui/react-form"
import { ReactComponent as ErrorSVG } from "images/form-error.svg"
import { ReactComponent as SuccessIcon } from "images/form-success.svg"
import { fmobile, fresponsive } from "library/fullyResponsive"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

export function Control({
	asTextArea = false,
	placeholder,
	required,
	name,
	type = "text",
}: {
	name: string
	placeholder?: string
	asTextArea?: boolean
	type?: "text" | "email" | "phone"
	required?: boolean
}) {
	return (
		<Field name={name}>
			<Form.Label />
			<Form.Control
				asChild
				required={required}
				placeholder={placeholder}
				type={type}
			>
				{asTextArea ? <TextArea /> : <Input />}
			</Form.Control>
			<Message match="valueMissing">This is required</Message>
			{type === "email" ? (
				<>
					<Message match="typeMismatch">Please enter a valid email</Message>
					{/* forbid dotless domains */}
					<Message match={(v) => !/@.*\./.test(v)}>
						Please enter a valid email
					</Message>
				</>
			) : null}
			{type === "phone" ? (
				<Message
					match={
						// any value with at least 7 digits
						(v) => v.replaceAll(/\D/g, "").length < 7
					}
				>
					Please enter a valid phone number
				</Message>
			) : null}
			<ErrorIcon />
			<Success />
		</Field>
	)
}

const Field = styled(Form.Field)`
  position: relative;
`

const areaStyles = css`
  ${textStyles.titleS};
  ${fresponsive(css`
    width: 100%;
    display: flex;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    background: ${colors.greenGray02};
    border: 1px solid transparent;
    transition: 0.1s ease-in-out;
    transition-property: border, background, color;
    padding-right: 40px;
    color: ${colors.greenPastel02};

    /* default state */
    &::placeholder {
      transition: 0.1s ease-in-out;
      transition-property: color;
      color: ${colors.greenGray01};
    }

    /* while hovered */
    &:hover {
      border: 1px solid ${colors.greenSaturated02};

      &::placeholder {
        color: ${colors.greenPastel02};
      }
    }

    /* while focused */
    &:focus {
      color: ${colors.greenDark01};
      background: ${colors.white};
      border: 1px solid transparent;
      outline: none;

      &::placeholder {
        color: ${colors.charcoal200};
      }
    }

    /* when invalid */
    &[data-invalid="true"] {
      border: 1px solid #f76161;
      background: ${colors.white};
      color: ${colors.greenDark01};

      &::placeholder {
        color: ${colors.charcoal200};
      }
    }

    /* these styles shouldn't apply, but because of a 1pass bug they might */
    &:autofill,
    &[data-com-onepassword-filled] {
      color: ${colors.greenDark01};
      background: ${colors.greenPastel02};
    }
  `)}
`

const TextArea = styled.textarea`
  ${areaStyles};
  ${fresponsive(css`
    height: 203px;
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    resize: none;
  `)}
`

const Input = styled.input`
  ${areaStyles};
  ${fresponsive(css`
    height: 48px;
  `)}
`

const Message = styled(Form.Message)`
  ${textStyles.bodyXS}
  ${fresponsive(css`
    color: #f76161;
    position: absolute;
    top: calc(100% + 4px);
    left: 16px;
  `)}

  ${fmobile(css`
    top: calc(100% - 1px);
  `)}
`

const iconStyles = css`
  ${fresponsive(css`
    display: none;
    position: absolute;
    right: 16px;
    top: 16px;
    height: 16px;
  `)}
`

const ErrorIcon = styled(ErrorSVG)`
  ${iconStyles}

  ${Field}[data-invalid="true"] & {
    display: block;
  }
`

const Success = styled(SuccessIcon)`
  ${iconStyles}

  ${Field}[data-valid="true"] & {
    display: block;
  }
`
