import gsap from "gsap";
import useAnimation from "library/useAnimation";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import colors from "styles/colors";

const ARROW_COLOR = colors.orangeDark01;

const INITIAL_DURATION = 1;
const PAUSE_TIME = 0.8;
const EASE = "power3.inOut";

export default function CornerArrows({ canPlay }: { canPlay: boolean }) {
  const [tl1, setTl1] = useState<gsap.core.Timeline | null>(null);
  const wrapperRef = useRef<SVGSVGElement | null>(null);
  const [topLeftInnerRef, setTopLeftInnerRef] = useState<SVGPathElement | null>(
    null,
  );
  const [bottomLeftInnerRef, setBottomLeftInnerRef] =
    useState<SVGPathElement | null>(null);
  const [topRightInnerRef, setTopRightInnerRef] =
    useState<SVGPathElement | null>(null);
  const [bottomRightInnerRef, setBottomRightInnerRef] =
    useState<SVGPathElement | null>(null);
  const [topLeftOuterRef, setTopLeftOuterRef] = useState<SVGPathElement | null>(
    null,
  );
  const [bottomLeftOuterRef, setBottomLeftOuterRef] =
    useState<SVGPathElement | null>(null);
  const [topRightOuterRef, setTopRightOuterRef] =
    useState<SVGPathElement | null>(null);
  const [bottomRightOuterRef, setBottomRightOuterRef] =
    useState<SVGPathElement | null>(null);

  const animationParams = useMemo(
    () => ({
      topLeftInner: {
        ref: topLeftInnerRef,
        set: {
          autoAlpha: 0,
          x: "100%",
          y: "100%",
        },
      },
      topRightInner: {
        ref: topRightInnerRef,
        set: {
          autoAlpha: 0,
          x: "-100%",
          y: "100%",
        },
      },
      bottomLeftInner: {
        ref: bottomLeftInnerRef,
        set: {
          autoAlpha: 0,
          y: "-100%",
          x: "100%",
        },
      },
      bottomRightInner: {
        ref: bottomRightInnerRef,
        set: {
          autoAlpha: 0,
          y: "100%",
          x: "-100%",
        },
      },
      topLeftOuter: {
        ref: topLeftOuterRef,
        set: {
          autoAlpha: 0,
          x: "-250%",
          y: "-250%",
        },
      },
      topRightOuter: {
        ref: topRightOuterRef,
        set: {
          autoAlpha: 0,
          y: "-250%",
          x: "250%",
        },
      },
      bottomLeftOuter: {
        ref: bottomLeftOuterRef,
        set: {
          autoAlpha: 0,
          y: "250%",
          x: "-250%",
        },
      },
      bottomRightOuter: {
        ref: bottomRightOuterRef,
        set: {
          autoAlpha: 0,
          y: "250%",
          x: "250%",
        },
      },
    }),
    [
      topLeftInnerRef,
      topRightInnerRef,
      bottomLeftInnerRef,
      bottomRightInnerRef,
      topLeftOuterRef,
      topRightOuterRef,
      bottomLeftOuterRef,
      bottomRightOuterRef,
    ],
  );

  useEffect(() => {
    const {
      topLeftInner,
      topRightInner,
      bottomLeftInner,
      bottomRightInner,
      topLeftOuter,
      topRightOuter,
      bottomLeftOuter,
      bottomRightOuter,
    } = animationParams;

    if (
      !topLeftInner.ref ||
      !topRightInner.ref ||
      !bottomLeftInner.ref ||
      !bottomRightInner.ref ||
      !topLeftOuter.ref ||
      !topRightOuter.ref ||
      !bottomLeftOuter.ref ||
      !bottomRightOuter.ref
    )
      return;

    gsap.set(topLeftOuter.ref, topLeftOuter.set);
    gsap.set(topRightOuter.ref, topRightOuter.set);
    gsap.set(bottomLeftOuter.ref, bottomLeftOuter.set);
    gsap.set(bottomRightOuter.ref, bottomRightOuter.set);

    const tl = gsap.timeline({
      repeat: -1,
      paused: true,
      repeatDelay: PAUSE_TIME,
    });

    // Top Left To Inner Arrows
    tl.to(
      topLeftOuter.ref,
      { x: 0, y: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
      0,
    ).to(
      topLeftOuter.ref,
      {
        x: "100%",
        y: "100%",
        duration: INITIAL_DURATION,
        autoAlpha: 0,
        ease: EASE,
      },
      INITIAL_DURATION + PAUSE_TIME,
    );
    tl.to(
      topLeftInner.ref,
      {
        x: "100%",
        y: "100%",
        autoAlpha: 0,
        duration: INITIAL_DURATION,
        ease: EASE,
        onComplete: () => {
          gsap.set(topLeftInner.ref, { x: "-250%", y: "-250%" });
        },
      },
      0,
    ).to(
      topLeftInner.ref,
      { x: 0, y: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
      INITIAL_DURATION + PAUSE_TIME,
    );
    // Top Right To Inner Arrows
    tl.to(
      topRightOuter.ref,
      { x: 0, y: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
      0,
    ).to(
      topRightOuter.ref,
      {
        x: "-100%",
        y: "100%",
        duration: INITIAL_DURATION,
        autoAlpha: 0,
        ease: EASE,
      },
      INITIAL_DURATION + PAUSE_TIME,
    );
    tl.to(
      topRightInner.ref,
      {
        x: "-100%",
        y: "100%",
        autoAlpha: 0,
        duration: INITIAL_DURATION,
        ease: EASE,
        onComplete: () => {
          gsap.set(topRightInner.ref, { x: "250%", y: "-250%" });
        },
      },
      0,
    ).to(
      topRightInner.ref,
      { x: 0, y: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
      INITIAL_DURATION + PAUSE_TIME,
    );
    // Bottom Left To Inner Arrows
    tl.to(
      bottomLeftOuter.ref,
      { x: 0, y: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
      0,
    ).to(
      bottomLeftOuter.ref,
      {
        x: "100%",
        y: "-100%",
        duration: INITIAL_DURATION,
        autoAlpha: 0,
        ease: EASE,
      },
      INITIAL_DURATION + PAUSE_TIME,
    );
    tl.to(
      bottomLeftInner.ref,
      {
        x: "100%",
        y: "-100%",
        autoAlpha: 0,
        duration: INITIAL_DURATION,
        ease: EASE,
        onComplete: () => {
          gsap.set(bottomLeftInner.ref, { x: "-250%", y: "250%" });
        },
      },
      0,
    ).to(
      bottomLeftInner.ref,
      { x: 0, y: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
      INITIAL_DURATION + PAUSE_TIME,
    );
    // Bottom Right To Inner Arrows
    tl.to(
      bottomRightOuter.ref,
      { x: 0, y: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
      0,
    ).to(
      bottomRightOuter.ref,
      {
        x: "-100%",
        y: "-100%",
        duration: INITIAL_DURATION,
        autoAlpha: 0,
        ease: EASE,
      },
      INITIAL_DURATION + PAUSE_TIME,
    );
    tl.to(
      bottomRightInner.ref,
      {
        x: "-100%",
        y: "-100%",
        autoAlpha: 0,
        duration: INITIAL_DURATION,
        ease: EASE,
        onComplete: () => {
          gsap.set(bottomRightInner.ref, { x: "250%", y: "250%" });
        },
      },
      0,
    ).to(
      bottomRightInner.ref,
      { x: 0, y: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
      INITIAL_DURATION + PAUSE_TIME,
    );

    setTl1(tl);
  }, [animationParams]);

  useAnimation(() => {
    if (canPlay) {
      tl1?.play();
    } else {
      tl1?.seek(0).pause();
    }
  }, [canPlay, tl1]);

  return (
    <Wrapper ref={wrapperRef} viewBox="0 0 120 120" fill="none">
      <path
        ref={setTopLeftInnerRef}
        d="M43.3615 42.5797C43.3615 42.6545 43.3318 42.7262 43.2789 42.7791L42.7599 43.2981C42.707 43.351 42.6352 43.3808 42.5604 43.3808L26.8417 43.3808L26.8417 41.1808L39.5997 41.1808L26.6821 28.2632L28.2378 26.7075L41.1615 39.6313L41.1615 26.8808H43.3615L43.3615 42.5797Z"
        fill={ARROW_COLOR}
      />
      <path
        ref={setBottomLeftInnerRef}
        d="M43.3615 77.4203C43.3615 77.3455 43.3318 77.2738 43.2789 77.2209L42.7599 76.7019C42.707 76.649 42.6352 76.6192 42.5604 76.6192L26.8417 76.6192L26.8417 78.8192L39.5997 78.8192L26.6821 91.7368L28.2378 93.2925L41.1615 80.3687L41.1615 93.1192H43.3615L43.3615 77.4203Z"
        fill={ARROW_COLOR}
      />
      <path
        ref={setTopRightInnerRef}
        d="M76.6385 42.5797C76.6385 42.6545 76.6682 42.7262 76.7211 42.7791L77.2401 43.2981C77.293 43.351 77.3648 43.3808 77.4396 43.3808L93.1583 43.3808L93.1583 41.1808L80.4003 41.1808L93.3179 28.2632L91.7622 26.7075L78.8385 39.6313L78.8385 26.8808H76.6385L76.6385 42.5797Z"
        fill={ARROW_COLOR}
      />
      <path
        ref={setBottomRightInnerRef}
        d="M76.6385 77.4203C76.6385 77.3455 76.6682 77.2738 76.7211 77.2209L77.2401 76.7019C77.293 76.649 77.3648 76.6192 77.4396 76.6192L93.1583 76.6192L93.1583 78.8192L80.4003 78.8192L93.3179 91.7368L91.7622 93.2925L78.8385 80.3687L78.8385 93.1192H76.6385L76.6385 77.4203Z"
        fill={ARROW_COLOR}
      />
      <path
        ref={setTopLeftOuterRef}
        d="M43.3615 42.5797C43.3615 42.6545 43.3318 42.7262 43.2789 42.7791L42.7599 43.2981C42.707 43.351 42.6352 43.3808 42.5604 43.3808L26.8417 43.3808L26.8417 41.1808L39.5997 41.1808L26.6821 28.2632L28.2378 26.7075L41.1615 39.6313L41.1615 26.8808H43.3615L43.3615 42.5797Z"
        fill={ARROW_COLOR}
      />
      <path
        ref={setBottomLeftOuterRef}
        d="M43.3615 77.4203C43.3615 77.3455 43.3318 77.2738 43.2789 77.2209L42.7599 76.7019C42.707 76.649 42.6352 76.6192 42.5604 76.6192L26.8417 76.6192L26.8417 78.8192L39.5997 78.8192L26.6821 91.7368L28.2378 93.2925L41.1615 80.3687L41.1615 93.1192H43.3615L43.3615 77.4203Z"
        fill={ARROW_COLOR}
      />
      <path
        ref={setTopRightOuterRef}
        d="M76.6385 42.5797C76.6385 42.6545 76.6682 42.7262 76.7211 42.7791L77.2401 43.2981C77.293 43.351 77.3648 43.3808 77.4396 43.3808L93.1583 43.3808L93.1583 41.1808L80.4003 41.1808L93.3179 28.2632L91.7622 26.7075L78.8385 39.6313L78.8385 26.8808H76.6385L76.6385 42.5797Z"
        fill={ARROW_COLOR}
      />
      <path
        ref={setBottomRightOuterRef}
        d="M76.6385 77.4203C76.6385 77.3455 76.6682 77.2738 76.7211 77.2209L77.2401 76.7019C77.293 76.649 77.3648 76.6192 77.4396 76.6192L93.1583 76.6192L93.1583 78.8192L80.4003 78.8192L93.3179 91.7368L91.7622 93.2925L78.8385 80.3687L78.8385 93.1192H76.6385L76.6385 77.4203Z"
        fill={ARROW_COLOR}
      />
    </Wrapper>
  );
}

const Wrapper = styled.svg`
  height: 100%;
  width: 100%;
`;
