import LinkButton from "components/Buttons/LinkButton";
import type { Link } from "components/Header/Desktop";
import { fresponsive } from "library/fullyResponsive";
import type { Dispatch, SetStateAction } from "react";
import styled, { css } from "styled-components";
import textStyles from "styles/text";

export default function AnimatedContent({
  links,
  section,
  color,
  setActiveIndex,
  setCanPlayIndex,
}: {
  links: Link[];
  section: string;
  color: string;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  setCanPlayIndex?: Dispatch<SetStateAction<number>>;
}) {
  const linkEls = links.map((link, i) => {
    return (
      <LinkWrapper key={link.href}>
        <LinkButton
          handleEnter={() => {
            setActiveIndex(i);
            if (!setCanPlayIndex) return;
            setCanPlayIndex(i);
          }}
          handleLeave={() => {
            setActiveIndex(-1);
            if (!setCanPlayIndex) return;
            setCanPlayIndex(-1);
          }}
          to={link.href}
          variant="arrow"
          dark
        >
          {link.name}
        </LinkButton>
        {link.description && (
          <Description $textColor={color}>{link.description}</Description>
        )}
      </LinkWrapper>
    );
  });

  return (
    <>
      <Column $withDescription={section === "Products"}>{linkEls}</Column>

      <IconWindow>
        {links.map((link, i) => (
          <IconWrapper className={`icon-${section}-${i}`} key={link.name}>
            {link.icon}
          </IconWrapper>
        ))}
      </IconWindow>
    </>
  );
}

const LinkWrapper = styled.div`
  position: flex;
  flex-direction: column;
  ${fresponsive(css`
    gap: 5px;
  `)}
`;

const Description = styled.p<{ $textColor: string }>`
  ${textStyles.bodyXXS};
  color: ${({ $textColor }) => $textColor};
`;

const Column = styled.div<{ $withDescription: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ $withDescription }) =>
    fresponsive(css`
      width: 160px;
      gap: ${$withDescription ? "20px" : "11px"};
    `)}
`;

const IconWindow = styled.div`
  position: relative;

  ${fresponsive(css`
    width: 120px;
    height: 120px;
  `)}
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  ${fresponsive(css`
    width: 120px;
    height: 120px;
  `)}

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;
