import LinkButton from "components/Buttons/LinkButton";
import Primary from "components/Buttons/Primary";
import Social from "components/Buttons/Social";
import { ReactComponent as IconSVG } from "images/global/icon.svg";
import { ReactComponent as LogoSVG } from "images/global/logo.svg";
import { ScreenContext } from "library/ScreenContext";
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive";
import { useContext } from "react";
import styled, { css } from "styled-components";
import colors from "styles/colors";
import { desktopBreakpoint } from "styles/media";
import textStyles from "styles/text";
import links from "utils/links";

import UniversalLink from "library/Loader/UniversalLink";
import { useNavConfig } from "./Providers/Nav";

const LINKS = [
  {
    section: "Products",
    links: [
      {
        name: "Revenue Automation",
        href: links.revenueAutomation,
      },
      {
        name: "Core Accounting",
        href: links.coreAccounting,
      },
    ],
  },
  {
    section: "Legal",
    links: [
      {
        name: "Terms and Conditions",
        href: links.terms,
      },
      {
        name: "Privacy Policy",
        href: links.privacy,
      },
    ],
  },
  {
    section: "Company",
    links: [
      {
        name: "Careers",
        href: links.careers,
      },
      {
        name: "Customers",
        href: links.customers,
      },
      {
        name: "Blog",
        href: links.blog,
      },
      {
        name: "Community",
        href: links.community,
      },
      {
        name: "Developers",
        href: links.developers,
      },
      {
        name: "Contact Us",
        href: links.contact,
      },
    ],
  },
  {
    section: "Solutions",
    links: [
      {
        name: "Startups",
        href: links.startups,
      },
      {
        name: "Mid-Market",
        href: links.midMarket,
      },
      {
        name: "Accounting Firms",
        href: links.accountingFirms,
      },
    ],
  },
];

export default function Footer() {
  const { color } = useNavConfig();
  const { mobile } = useContext(ScreenContext);

  const sections = LINKS.map((section) => {
    const allLinks = section.links.map((link) => (
      <StyledLinkButton to={link.href} key={link.name}>
        {link.name}
      </StyledLinkButton>
    ));

    return (
      <Links key={section.section}>
        <Label style={{ color: colors[`${color}Gray02`] }}>
          {section.section}
        </Label>
        {allLinks}
      </Links>
    );
  });

  if (mobile) {
    const temp = sections[3];
    if (sections[2]) {
      sections[3] = sections[2];
    }
    if (temp) {
      sections[2] = temp;
    }
  }

  return (
    <Wrapper>
      <Inner>
        <Cta>
          <UniversalLink to={links.home} ariaLabel="to home">
            <Icon />
          </UniversalLink>
          <CtaLabel>Join the circle.</CtaLabel>
          <Primary to={links.getStarted}>Get Started</Primary>
        </Cta>
        <Right style={{ backgroundColor: colors[`${color}Dark01`] }}>
          <Sections>{sections}</Sections>
          <Bottom>
            <Copywrite style={{ color: colors[`${color}Gray01`] }}>
              2024 © Copyright Campfire Software, Inc.
            </Copywrite>
            <Socials>
              <Social icon="linkedin" to={links.linkedin} color={color} />
              <Social icon="x" to={links.twitter} color={color} />
            </Socials>
          </Bottom>
        </Right>
        <Logo $color={color} />
      </Inner>
    </Wrapper>
  );
}

const StyledLinkButton = styled(LinkButton)`
  ${fmobile(css`
    div {
      ${textStyles.bodyS};
    }
  `)}
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;

  ${fresponsive(css`
    gap: 10px;
  `)}
`;

const Label = styled.span`
  ${textStyles.titleS}
`;

const Wrapper = styled.footer`
  display: flex;
  justify-content: center;
  width: 100vw;
  background-color: ${colors.white};
`;

const Inner = styled.div`
  width: 100%;
  max-width: ${desktopBreakpoint}px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  overflow: clip;

  ${fresponsive(css`
    height: 840px;
    padding: 130px 110px 320px;
  `)}

  ${ftablet(css`
    height: 1058px;
    padding: 0;
    padding-top: 83px;
    flex-direction: column;
    gap: 80px;
    justify-content: flex-start;
  `)}

  ${fmobile(css`
    flex-direction: column;
    height: 891px;
    padding: 67px 21px 114px;
  `)}
`;

const Cta = styled.div`
  display: flex;
  flex-direction: column;

  ${ftablet(css`
    align-items: center;
    gap: 35px;
  `)}

  ${fmobile(css`
    gap: 35px;
    width: 100%;

    a,
    button {
      width: 100%;
    }
  `)}
`;

const Icon = styled(IconSVG)`
  height: auto;

  ${fresponsive(css`
    width: 87px;
    margin-bottom: 98px;
  `)}

  ${ftablet(css`
    margin-bottom: 0;
  `)}

  ${fmobile(css`
    margin-bottom: 0;
  `)}
`;

const CtaLabel = styled.span`
  ${textStyles.h5}

  ${fresponsive(css`
    margin-bottom: 35px;
  `)}

  ${ftablet(css`
    margin-bottom: 0;
  `)}

  ${fmobile(css`
    margin-bottom: 0;
  `)}
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${fresponsive(css`
    border-radius: 40px;
    width: 706px;
    height: 389px;
    padding: 55px;
  `)}

  ${ftablet(css`
    border-radius: 40px;
    width: 787px;
    height: 421px;
    padding: 65px;
  `)}

  ${fmobile(css`
    width: 355px;
    border-radius: 30px;
    height: 419px;
    padding: 47px 30px 35px;
  `)}
`;

const Sections = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;

  ${fresponsive(css`
    gap: 30px 96px;
    height: 200px;
  `)}

  ${ftablet(css`
    height: 222px;
  `)}

  ${fmobile(css`
    height: 100%;
    gap: 30px 28px;
  `)}
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${fmobile(css`
    flex-direction: column;
    gap: 30px;
    justify-content: unset;
    align-items: flex-start;
    position: absolute;
    right: 30px;
    bottom: 35px;
    width: 108px;
  `)}
`;

const Copywrite = styled.p`
  ${textStyles.bodyXS}

  ${fresponsive(css`
    width: 136px;
  `)}

  ${fmobile(css`
    ${textStyles.bodyXXS}
    width: unset;
  `)}
`;

const Socials = styled.div`
  display: flex;
  align-items: center;

  ${fresponsive(css`
    gap: 20px;
  `)}
`;

const Logo = styled(LogoSVG)<{ $color: "orange" | "purple" | "green" }>`
  position: absolute;
  height: auto;
  left: 50%;
  transform: translateX(-50%);

  path {
    fill: ${({ $color }) => colors[`${$color}Dark01`]};
  }

  ${fresponsive(css`
    width: 1404px;
    bottom: -140px;
  `)}

  ${ftablet(css`
    width: 1014px;
    bottom: -94px;
  `)}

  ${fmobile(css`
    width: 367px;
    bottom: -35px;
  `)}
`;
