import { fresponsive } from "library/fullyResponsive";
import { useRef } from "react";
import styled, { css } from "styled-components";
import type colors from "styles/colors";

import ClusterArrows from "./ClusterArrows";
import ClusterArrowsDown from "./ClusterArrowsDown";
import CornerArrows from "./CornerArrows";
import SwirlArrows from "./SwirlArrows";
import TriangleRowsArrows from "./TriangleRowsArrows";

export type Animations =
  | "clusterArrows"
  | "swirlArrows"
  | "cornerArrows"
  | "triangleRows"
  | "clusterDown";

export default function AnimatedIcons({
  animation,
  color,
  canPlay = false,
  className = "",
}: {
  color: (typeof colors)[keyof typeof colors];
  animation: Animations;
  canPlay?: boolean;
  className?: string;
}) {
  const mainWrapperRef = useRef<HTMLDivElement>(null);

  const schemes = {
    clusterArrows: {
      animation: ClusterArrows,
    },
    swirlArrows: {
      animation: SwirlArrows,
    },
    cornerArrows: {
      animation: CornerArrows,
    },
    triangleRows: {
      animation: TriangleRowsArrows,
    },
    clusterDown: {
      animation: ClusterArrowsDown,
    },
  };

  const scheme = schemes[animation];

  return (
    <Wrapper
      className={className}
      style={{ backgroundColor: color }}
      ref={mainWrapperRef}
    >
      <scheme.animation canPlay={canPlay} />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  overflow: clip;
  position: relative;

  ${fresponsive(css`
    width: 120px;
    height: 120px;
    border-radius: 15px;
  `)}
`;
