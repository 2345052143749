import gsap from "gsap"
import useAnimation from "library/useAnimation"
import { useMemo, useState } from "react"
import styled from "styled-components"

const INITIAL_DURATION = 1
const PAUSE_TIME = 0.8
const EASE = "power3.inOut"
const ARROW_COLOR = "#1A0948" // TODO: Add to colors?

export default function SwirlArrows({ canPlay }: { canPlay: boolean }) {
	const [tl1, setTl1] = useState<gsap.core.Timeline | null>(null)
	const [inFromLeftRef, setInFromLeftRef] = useState<SVGPathElement | null>(
		null,
	)
	const [inFromRightRef, setInFromRightRef] = useState<SVGPathElement | null>(
		null,
	)
	const [inFromTopRef, setInFromTopRef] = useState<SVGPathElement | null>(null)
	const [inFromBottomRef, setInFromBottomRef] = useState<SVGPathElement | null>(
		null,
	)
	const [outToRightRef, setOutToRightRef] = useState<SVGPathElement | null>(
		null,
	)
	const [outToLeftRef, setOutToLeftRef] = useState<SVGPathElement | null>(null)
	const [outToBottomRef, setOutToBottomRef] = useState<SVGPathElement | null>(
		null,
	)
	const [outToTopRef, setOutToTopRef] = useState<SVGPathElement | null>(null)

	const animationParams = useMemo(
		() => ({
			inFromLeft: {
				id: "#in-from-left",
				ref: inFromLeftRef,
				set: {
					autoAlpha: 0,
					x: "-333%",
				},
			},
			inFromRight: {
				id: "#in-from-right",
				ref: inFromRightRef,
				set: {
					autoAlpha: 0,
					x: "333%",
				},
			},
			inFromTop: {
				id: "#in-from-top",
				ref: inFromTopRef,
				set: {
					autoAlpha: 0,
					y: "-333%",
				},
			},
			inFromBottom: {
				id: "#in-from-bottom",
				ref: inFromBottomRef,
				set: {
					autoAlpha: 0,
					y: "333%",
				},
			},
			outToRight: {
				id: "#out-to-right",
				ref: outToRightRef,
			},
			outToLeft: {
				id: "#out-to-left",
				ref: outToLeftRef,
			},
			outToTop: {
				id: "#out-to-top",
				ref: outToTopRef,
			},
			outToBottom: {
				id: "#out-to-bottom",
				ref: outToBottomRef,
			},
		}),
		[
			inFromLeftRef,
			inFromRightRef,
			inFromTopRef,
			inFromBottomRef,
			outToRightRef,
			outToLeftRef,
			outToTopRef,
			outToBottomRef,
		],
	)

	useAnimation(() => {
		const {
			inFromLeft,
			inFromRight,
			inFromTop,
			inFromBottom,
			outToRight,
			outToLeft,
			outToBottom,
			outToTop,
		} = animationParams

		gsap.set(inFromLeft.ref, inFromLeft.set)
		gsap.set(inFromRight.ref, inFromRight.set)
		gsap.set(inFromTop.ref, inFromTop.set)
		gsap.set(inFromBottom.ref, inFromBottom.set)

		const tl = gsap.timeline({
			repeat: -1,
			paused: true,
			repeatDelay: PAUSE_TIME,
		})
		// Top Left To Right Arrows
		tl.to(
			inFromLeft.ref,
			{ x: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
			0,
		).to(
			inFromLeft.ref,
			{
				x: "333%",
				duration: INITIAL_DURATION,
				autoAlpha: 0,
				ease: EASE,
			},
			INITIAL_DURATION + PAUSE_TIME,
		)
		tl.to(
			outToRight.ref,
			{
				x: "333%",
				duration: INITIAL_DURATION,
				autoAlpha: 0,
				ease: EASE,
				onComplete: () => {
					gsap.set(outToRight.ref, { x: "-333%", autoAlpha: 0 })
				},
			},
			0,
		).to(
			outToRight.ref,
			{
				x: 0,
				duration: INITIAL_DURATION,
				autoAlpha: 1,
				ease: EASE,
			},
			INITIAL_DURATION + PAUSE_TIME,
		)
		// Bottom Right To Left Arrows
		tl.to(
			inFromRight.ref,
			{ x: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
			0,
		).to(
			inFromRight.ref,
			{
				x: "-333%",
				duration: INITIAL_DURATION,
				autoAlpha: 0,
				ease: EASE,
			},
			INITIAL_DURATION + PAUSE_TIME,
		)
		tl.to(
			outToLeft.ref,
			{
				x: "-333%",
				duration: INITIAL_DURATION,
				autoAlpha: 0,
				ease: EASE,
				onComplete: () => {
					gsap.set(outToLeft.ref, { x: "333%", autoAlpha: 0 })
				},
			},
			0,
		).to(
			outToLeft.ref,
			{
				x: 0,
				duration: INITIAL_DURATION,
				autoAlpha: 1,
				ease: EASE,
			},
			INITIAL_DURATION + PAUSE_TIME,
		)
		// Right Top To Bottom Arrows
		tl.to(
			inFromTop.ref,
			{ y: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
			0,
		).to(
			inFromTop.ref,
			{
				y: "333%",
				duration: INITIAL_DURATION,
				autoAlpha: 0,
				ease: EASE,
			},
			INITIAL_DURATION + PAUSE_TIME,
		)
		tl.to(
			outToBottom.ref,
			{
				y: "333%",
				duration: INITIAL_DURATION,
				autoAlpha: 0,
				ease: EASE,
				onComplete: () => {
					gsap.set(outToBottom.ref, { y: "-333%", autoAlpha: 0 })
				},
			},
			0,
		).to(
			outToBottom.ref,
			{
				y: 0,
				duration: INITIAL_DURATION,
				autoAlpha: 1,
				ease: EASE,
			},
			INITIAL_DURATION + PAUSE_TIME,
		)
		// Left Bottom to Top Arrows
		tl.to(
			inFromBottom.ref,
			{ y: 0, duration: INITIAL_DURATION, autoAlpha: 1, ease: EASE },
			0,
		).to(
			inFromBottom.ref,
			{
				y: "-333%",
				duration: INITIAL_DURATION,
				autoAlpha: 0,
				ease: EASE,
			},
			INITIAL_DURATION + PAUSE_TIME,
		)
		tl.to(
			outToTop.ref,
			{
				y: "-333%",
				duration: INITIAL_DURATION,
				autoAlpha: 0,
				ease: EASE,
				onComplete: () => {
					gsap.set(outToTop.ref, { y: "333%", autoAlpha: 0 })
				},
			},
			0,
		).to(
			outToTop.ref,
			{
				y: 0,
				duration: INITIAL_DURATION,
				autoAlpha: 1,
				ease: EASE,
			},
			INITIAL_DURATION + PAUSE_TIME,
		)

		setTl1(tl)
	}, [animationParams])

	useAnimation(() => {
		if (canPlay) {
			tl1?.play()
		} else {
			tl1?.seek(0).pause()
		}
	}, [canPlay, tl1])

	return (
		<Wrapper viewBox="0 0 120 120" fill="none">
			<path
				ref={setOutToRightRef}
				d="M71.2924 27.4241C71.3453 27.477 71.375 27.5488 71.375 27.6236V28.3576C71.375 28.4324 71.3453 28.5042 71.2924 28.557L60.1776 39.6719L58.6219 38.1162L67.6432 29.095H49.375V26.895H67.6519L58.6359 17.879L60.1916 16.3233L71.2924 27.4241Z"
				fill={ARROW_COLOR}
			/>
			<path
				ref={setOutToLeftRef}
				d="M48.7076 91.4241C48.6547 91.477 48.625 91.5488 48.625 91.6236V92.3576C48.625 92.4324 48.6547 92.5042 48.7076 92.557L59.8224 103.672L61.3781 102.116L52.3568 93.095H70.625V90.895H52.3481L61.3641 81.879L59.8084 80.3233L48.7076 91.4241Z"
				fill={ARROW_COLOR}
			/>
			<path
				ref={setOutToBottomRef}
				d="M92.5719 71.2924C92.5191 71.3453 92.4473 71.375 92.3725 71.375L91.6385 71.375C91.5637 71.375 91.4919 71.3453 91.439 71.2924L80.3242 60.1776L81.8799 58.6219L90.9011 67.6432L90.9011 49.375L93.1011 49.375L93.1011 67.6519L102.117 58.6359L103.673 60.1916L92.5719 71.2924Z"
				fill={ARROW_COLOR}
			/>
			<path
				ref={setOutToTopRef}
				d="M28.5719 48.7076C28.5191 48.6547 28.4473 48.625 28.3725 48.625L27.6385 48.625C27.5637 48.625 27.4919 48.6547 27.439 48.7076L16.3242 59.8224L17.8799 61.3781L26.9011 52.3568L26.9011 70.625L29.1011 70.625L29.1011 52.3481L38.1171 61.3641L39.6728 59.8084L28.5719 48.7076Z"
				fill={ARROW_COLOR}
			/>
			<path
				ref={setInFromLeftRef}
				d="M71.2924 27.4241C71.3453 27.477 71.375 27.5488 71.375 27.6236V28.3576C71.375 28.4324 71.3453 28.5042 71.2924 28.557L60.1776 39.6719L58.6219 38.1162L67.6432 29.095H49.375V26.895H67.6519L58.6359 17.879L60.1916 16.3233L71.2924 27.4241Z"
				fill={ARROW_COLOR}
			/>
			<path
				ref={setInFromRightRef}
				d="M48.7076 91.4241C48.6547 91.477 48.625 91.5488 48.625 91.6236V92.3576C48.625 92.4324 48.6547 92.5042 48.7076 92.557L59.8224 103.672L61.3781 102.116L52.3568 93.095H70.625V90.895H52.3481L61.3641 81.879L59.8084 80.3233L48.7076 91.4241Z"
				fill={ARROW_COLOR}
			/>
			<path
				ref={setInFromTopRef}
				d="M92.5719 71.2924C92.5191 71.3453 92.4473 71.375 92.3725 71.375L91.6385 71.375C91.5637 71.375 91.4919 71.3453 91.439 71.2924L80.3242 60.1776L81.8799 58.6219L90.9011 67.6432L90.9011 49.375L93.1011 49.375L93.1011 67.6519L102.117 58.6359L103.673 60.1916L92.5719 71.2924Z"
				fill={ARROW_COLOR}
			/>
			<path
				ref={setInFromBottomRef}
				d="M28.5719 48.7076C28.5191 48.6547 28.4473 48.625 28.3725 48.625L27.6385 48.625C27.5637 48.625 27.4919 48.6547 27.439 48.7076L16.3242 59.8224L17.8799 61.3781L26.9011 52.3568L26.9011 70.625L29.1011 70.625L29.1011 52.3481L38.1171 61.3641L39.6728 59.8084L28.5719 48.7076Z"
				fill={ARROW_COLOR}
			/>
		</Wrapper>
	)
}

const Wrapper = styled.svg`
  height: 100%;
  width: 100%;
`
