const colors = {
	white: "#F9F9F8",
	pureWhite: "#FFFFFF",
	pureBlack: "#000000",
	charcoal700: "#101012",
	charcoal500: "#535353",
	charcoal300: "#9B9B9B",
	charcoal200: "#D2D2D2",
	charcoal100: "#101012",
	greenBlack: "#111816",
	greenDark01: "#142D25",
	greenDark02: "#0A4733",
	greenGray01: "#52644D",
	greenGray02: "#869C80",
	greenSaturated01: "#8BEC5C",
	greenSaturated02: "#B3ED97",
	greenPastel01: "#DAECDE",
	greenPastel02: "#ECF6EF",
	orangeBlack: "#1C1209",
	orangeDark01: "#301805",
	orangeDark02: "#5B2B04",
	orangeGray01: "#8A6240",
	orangeGray02: "#B09278",
	orangeSaturated01: "#FF862F",
	orangeSaturated02: "#F7A061",
	orangePastel01: "#EEE4D8",
	orangePastel02: "#F9F4EE",
	purpleBlack: "#17151C",
	purpleDark01: "#1A0948",
	purpleDark02: "#3E2878",
	purpleGray01: "#645A7D",
	purpleGray02: "#988FAE",
	purpleSaturated01: "#AC8FFC",
	purpleSaturated02: "#C5B4F3",
	purplePastel01: "#E1DCED",
	purplePastel02: "#F3F2F6",
} as const

export default colors
