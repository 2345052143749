import gsap from "gsap"
import ScrollSmoother from "gsap/ScrollSmoother"
import { ReactComponent as LogoSVG } from "images/global/logo.svg"
import {
	registerLoaderCallback,
	unregisterLoaderCallback,
} from "library/Loader/LoaderUtils"
import { fmobile, fresponsive } from "library/fullyResponsive"
import { useCallback, useEffect, useRef } from "react"
import styled, { css } from "styled-components"
import colors from "styles/colors"
import textStyles from "styles/text"

const ICON_LINE = ".preloader-line"

export default function Preloader() {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const canSlideOut = useRef<boolean>(false)

	const slideOut = () => {
		const tl = gsap.timeline()

		tl.to(
			ICON_LINE,
			{
				rotate: "+=45",
			},
			0,
		)

		tl.to(
			[".preloader-icon", ".preloader-logo"],
			{
				yPercent: 100,
			},
			0,
		)

		tl.to(
			wrapperRef.current,
			{
				autoAlpha: 0,
				duration: 0.5,
			},
			1,
		)
	}

	const rotate = useCallback(() => {
		const self = gsap.to(ICON_LINE, {
			rotate: "+=45",
			ease: "none",
			duration: 0.5,
			repeat: -1,
			onRepeat: () => {
				if (canSlideOut.current) {
					self.pause()
					slideOut()
				}
			},
		})
	}, [slideOut])

	const slideIn = useCallback(() => {
		const tl = gsap.timeline({
			onComplete: () => {
				ScrollSmoother.get()?.scrollTo(0)
				if (canSlideOut.current) {
					slideOut()
				} else {
					rotate()
				}
			},
		})

		tl.to(
			[".preloader-icon", ".preloader-logo"],
			{
				y: 0,
				duration: 0.75,
			},
			0,
		)
		tl.set(
			ICON_LINE,
			{
				transformOrigin: "center bottom",
				duration: 0.5,
			},
			0.75,
		)
		tl.to(
			ICON_LINE,
			{
				duration: 0.5,
				rotate: (index) => {
					return -90 + index * 45
				},
			},
			0.75,
		)
		tl.set(
			".preloader-line-2",
			{
				opacity: 1,
			},
			1.25,
		)
	}, [rotate, slideOut])

	useEffect(() => {
		const setCanSlideOut = () => {
			canSlideOut.current = true
		}

		slideIn()

		registerLoaderCallback({
			callback: setCanSlideOut,
			duration: 1.5,
		})

		return () => {
			unregisterLoaderCallback(setCanSlideOut)
		}
	}, [slideIn])

	return (
		<Wrapper ref={wrapperRef}>
			<Center>
				<IconWrapper>
					<Icon className="preloader-icon" viewBox="0 0 104 61">
						<Line className="preloader-line" x1="52" x2="52" y1="58" y2="8" />
						<Line className="preloader-line" x1="52" x2="52" y1="58" y2="8" />
						<Line className="preloader-line" x1="52" x2="52" y1="58" y2="8" />
						<Line className="preloader-line" x1="52" x2="52" y1="58" y2="8" />
						<Line className="preloader-line" x1="52" x2="52" y1="58" y2="8" />
						<Line className="preloader-line-2" x1="52" x2="2" y1="58" y2="58" />
						<Line
							className="preloader-line-2"
							x1="52"
							x2="102"
							y1="58"
							y2="58"
						/>
						<line
							stroke={colors.greenDark01}
							strokeWidth="4"
							x1="44"
							x2="59"
							y1="61"
							y2="61"
						/>

						<path
							d="M51.734 54.6362C47.0751 54.6362 44.9173 61 39.6208 61L63.8472 61C58.5507 61 56.3929 54.6362 51.734 54.6362Z"
							fill={colors.greenDark01}
						/>
					</Icon>
				</IconWrapper>
				<LogoWrapper>
					<Logo className="preloader-logo" />
				</LogoWrapper>
			</Center>
		</Wrapper>
	)
}

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.greenDark01};
  z-index: 100;
  pointer-events: none;
  ${textStyles.h1}
`

const Center = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${fresponsive(css`
    gap: 30px;
  `)}

  ${fmobile(css`
    scale: 0.5;
    transform-origin: left center;
  `)}
`

const IconWrapper = styled.div`
  overflow: clip;
  display: flex;
  position: relative;

  ${fresponsive(css`
    top: 10px;
  `)}
`

const Icon = styled.svg`
  height: auto;
  position: relative;
  transform: translateY(-100%);
  overflow: clip;

  ${fresponsive(css`
    width: 103px;
  `)}
`

const Line = styled.line`
  stroke: ${colors.orangeSaturated01};

  &.preloader-line-2 {
    opacity: 0;
  }
  ${fresponsive(css`
    stroke-width: 8px;
  `)}
`

const LogoWrapper = styled.div`
  display: flex;
  overflow: clip;
`

const Logo = styled(LogoSVG)`
  height: auto;
  transform: translateY(100%);

  path {
    fill: ${colors.white};
  }

  ${fresponsive(css`
    width: 394px;
  `)}
`
