import * as Dialog from "@radix-ui/react-dialog";
import { ModalContext } from "components/Providers/Modal";
import gsap from "gsap";
import { ReactComponent as CampfireLogo } from "images/global/logoFull.svg";
import { ReactComponent as CloseSVG } from "images/header/x.svg";
import { fmobile, fresponsive, ftablet } from "library/fullyResponsive";
import getMedia from "library/getMedia";
import useAnimation from "library/useAnimation";
import { startTransition, useContext, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import colors from "styles/colors";

import ModalContent, { DialogText, TopShadow } from "./ModalContent";

export default function ContactModal({
  children,
  type = "contact",
}: {
  children?: React.ReactNode;
  type?: "contact" | "demo";
}) {
  /**
   * for entry/exit animations
   */
  const { type: contextType, setType: setContextType } =
    useContext(ModalContext);
  const [modalVisible, setModalVisible] = useState<boolean>(
    contextType === type,
  );
  const [clipper, setClipper] = useState<HTMLDivElement | null>(null);
  const [overlay, setOverlay] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setModalVisible(type === contextType);
  }, [contextType, type]);

  const timeline = useAnimation(
    () => {
      if (!clipper || !overlay) return null;
      const breakpoint = getMedia("desktop", "desktop", "tablet", "mobile");
      const panel = clipper.children.item(0);
      const horizontalEase = "power1.in";
      const verticalEase = "power1.out";

      const tl = gsap.timeline({
        paused: true,
        onReverseComplete: () => setModalVisible(false),
      });

      // animating the shape and position of the clipper
      tl.set(clipper, {
        opacity: 1,
      });
      tl.fromTo(
        clipper,
        {
          width: "10vw",
          x: "38vw",
        },
        {
          x: "0vw",
          xPercent: -50,
          width: "auto",
          ease: horizontalEase,
        },
        0,
      );
      tl.fromTo(
        clipper,
        {
          y: "-100vh",
          height: "10vw",
        },
        {
          y: "0vh",
          yPercent: -50,
          height: "auto",
          ease: verticalEase,
        },
        0,
      );

      // animate the position of the form
      // on the x axis, this remains in place
      // on mobile, it's important this element has no transform properties
      if (breakpoint !== "mobile")
        tl.fromTo(
          "form",
          {
            x: "-76vw",
            opacity: 0,
          },
          {
            x: "0vw",
            opacity: 1,
            ease: horizontalEase,
          },
          0,
        );
      // on tablet, it slides down from the top
      if (breakpoint === "tablet")
        tl.fromTo(
          "form",
          {
            y: "-60vw",
          },
          {
            y: "0vw",
            ease: horizontalEase,
          },
          0,
        );

      // animate the position of the dialog title and description
      // on desktop this slides in from the top
      if (breakpoint === "desktop")
        tl.fromTo(
          String(DialogText),
          {
            x: "-76vw",
          },
          {
            x: "0vw",
            ease: horizontalEase,
          },
          0,
        );
      // on tablet it slides down from the top
      else if (breakpoint === "tablet") {
        tl.fromTo(
          String(DialogText),
          {
            y: "-30vw",
            x: "-76vw",
          },
          {
            y: "0vw",
            x: "0vw",
            ease: horizontalEase,
          },
          0,
        );
        tl.fromTo(
          String(TopShadow),
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 0.01,
            delay: 0.49,
          },
          0,
        );
      }

      // on mobile, we want to maintain the x position of all the content
      if (breakpoint === "mobile") {
        tl.fromTo(
          panel,
          {
            x: "-76vw",
          },
          {
            x: "0vw",
            ease: horizontalEase,
          },
          0,
        );
        tl.from(
          "input, textarea",
          {
            width: 0,
            ease: verticalEase,
          },
          0,
        );
      }

      // and last but not least, fade in the overlay
      tl.to(
        overlay,
        {
          opacity: 1,
        },
        0,
      );

      return gsap.fromTo(
        tl,
        { progress: 0 },
        { progress: 1, duration: 0.8, ease: "power2.out" },
      );
    },
    [clipper, overlay],
    {
      recreateOnResize: true,
      scope: clipper,
    },
  );

  useEffect(() => {
    if (modalVisible) {
      timeline?.play();
    } else {
      timeline?.reverse();
    }
  }, [modalVisible, timeline]);

  return (
    <Dialog.Root
      open={modalVisible}
      onOpenChange={(open) => {
        // it's okay if this change is delayed a small amount, and we get much smoother animations in return
        startTransition(() => {
          setContextType(open ? type : null);
        });
      }}
    >
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal forceMount>
        {modalVisible ? (
          <>
            <Overlay forceMount ref={setOverlay} />
            <Clipper
              ref={setClipper}
              forceMount
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              <Logo />
              <Close>
                <CloseIcon />
              </Close>
              <ModalContent type={type} />
            </Clipper>
          </>
        ) : null}
      </Dialog.Portal>
    </Dialog.Root>
  );
}

const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 100vw;
  backdrop-filter: blur(5px);
  opacity: 0;
`;

const Clipper = styled(Dialog.Content)`
  ${fresponsive(css`
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 102;
    border-radius: 30px;
    overflow: clip;
    box-shadow: 0 8px 10px 0 rgb(0 0 0 / 10%);
    opacity: 0;
  `)};
`;

const Logo = styled(CampfireLogo)`
  display: none;
  ${fmobile(css`
    display: block;
    position: absolute;
    z-index: 3;
    top: 26px;
    left: 21px;
    width: 125px;

    path:not(:last-child) {
      fill: ${colors.white};
    }
  `)}
`;

const Close = styled(Dialog.Close)`
  ${fresponsive(css`
    position: absolute;
    cursor: pointer;
    z-index: 3;
    top: 30px;
    right: 40px;
    width: 72px;
    height: 56px;
    border-radius: 99vw;
    background: ${colors.greenDark01};
    display: grid;
    place-items: center;
  `)}

  ${ftablet(css`
    top: 28px;
    right: 32px;
  `)}

  ${fmobile(css`
    top: 10px;
    right: 10px;
  `)}
`;

const CloseIcon = styled(CloseSVG)`
  ${fresponsive(css`
    width: 16px;

    path {
      stroke: ${colors.greenSaturated02};
    }
  `)}
`;
