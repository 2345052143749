import { ScreenProvider } from "library/ScreenContext"

import ModalProvider from "./Modal"
import NavProvider from "./Nav"

interface ProvidersProps {
	children: React.ReactNode
}

export default function Providers({ children }: ProvidersProps) {
	return (
		<ScreenProvider>
			<ModalProvider>
				<NavProvider>{children}</NavProvider>
			</ModalProvider>
		</ScreenProvider>
	)
}
